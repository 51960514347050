import React, { useEffect } from 'react';
import { Input, Button, Form, message } from 'antd';
import { } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateSupportPhone, getSupportPhone } from '../../redux/admin'
// import { HTTP_STATUS } from '../../utils/constants';

export default function SupportNumber() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { supportPhone } = useSelector(state => state.admin)
    const [messageApi, contextHolder] = message.useMessage();

    const successMessage = () => {
        messageApi.open({
            type: 'success',
            content: 'The support mobile number has been updated successfully!',
        });
    };

    const errorMessage = () => {
        messageApi.open({
            type: 'error',
            content: 'Ooops.. something went wrong, please try again.',
        });
    };

    useEffect(() => {
        dispatch(getSupportPhone())
    }, [])

    const onFinish = (values) => {
        // console.log('Success:', values);
        const data = {
            supportPhone: values.mobileNumber,
            successMessage,
            errorMessage,
        }

        dispatch(updateSupportPhone(data))
        // success()
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div>
            {contextHolder}
            <Form
                name="basic"
                // labelCol={{
                //     span: 8,
                // }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                    margin: 20
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    initialValue={supportPhone}
                    label="WhatsApp Support Number"
                    name="mobileNumber"
                    rules={[
                        {
                            required: true,
                            // type: 'number',
                            pattern: /^(\+?01)[0|1|2|3|4|6|7|8|9]-*[0-9]{7,8}$/,
                            message: 'Please input a support number!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
