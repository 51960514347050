import { configureStore } from '@reduxjs/toolkit'
import {combineReducers} from "redux"; 
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import adminReducer from './admin'
import userReducer from './user'
import agentReducer from './agent'
import merchantReducer from './merchant'
import foodOrderReducer from './foodOrder'
import ratingReducer from './rating'
import messageReducer from './message'
import couponReducer from './coupon'

// ADD REDUCERS HERE
const rootReducer = combineReducers({
  admin: adminReducer,          
  user: userReducer,          
  agent: agentReducer,          
  merchant: merchantReducer,          
  foodOrder: foodOrderReducer,          
  rating: ratingReducer,          
  message: messageReducer,          
  coupon: couponReducer,          
 });

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['admin'] // only navigation will be persisted
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  // reducer: {admin:persistedReducer},
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

let persistor = persistStore(store);

export { store, persistor };

// const store = configureStore({
//   reducer: {
//     admin: adminReducer,
//   }
// })

// export default store