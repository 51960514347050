import React, { useEffect, useState } from 'react';
import './user.css'
import { images } from '../../assets'
import { useNavigate } from 'react-router-dom'
import { Typography, Stack, Button, Card, CardContent, Grid, Avatar, TextField, Box, IconButton } from '@mui/material';
import { PhotoCamera, PhoneAndroid, EmailOutlined, CalendarToday, LocationOn } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedUser, updateUser, updateUserProfilePhoto } from '../../redux/user'
import { HTTP_STATUS } from '../../utils/constants'
import {format} from 'date-fns'
import { ref, getDownloadURL, uploadBytes } from "firebase/storage"
import storage from "../../firebase"
// import {} from '../../redux/user'

export default function User() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { selectedUserId, user, updateUserStatus, loading } = useSelector(state => state.user)
    const [photo, setPhoto] = useState(user?.profilePhoto)

    useEffect(() => {
        dispatch(getSelectedUser(selectedUserId))
    }, [])

    // console.log(user)

    const Input = styled('input')({
        display: 'none',
    });

    const handleSubmit = async event => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        data.append('id', selectedUserId)
        // const finalPhoto = typeof photo === 'object' ? photo : user?.profilePhoto
        // data.append('profilePhotoURL', finalPhoto)
        // console.log(data.get('address'))

        try {

            if (typeof photo === 'object') {
                const storageRef = ref(storage, `users/${selectedUserId}/profilePhoto.jpg`)
                uploadBytes(storageRef, photo)
                    .then((snapshot) => {
                        // e.target[0].value = ''
                        getDownloadURL(snapshot.ref).then((downloadURL) => {
                            // DISPATCH TO UPDATE PROFILE PHOTO ONLY
                            const userdata = new FormData()
                            userdata.append('profilePhoto', downloadURL)
                            userdata.append('id', selectedUserId)
                            dispatch(updateUserProfilePhoto(userdata))
                        })
                    })
            }

            dispatch(updateUser(data))
            // const userData = {
            //     firstName: data.get('firstName'),
            //     lastName: data.get('lastName'),
            //     email: data.get('email'),
            //     mobilePhone: data.get('mobilePhone'),
            //     address: data.get('address'),
            // }

            // console.log(photo)
            // console.log('profilePhoto', data.get('profilePhoto'))
        } catch (error) {
            console.log(error)
        }

    }

    // useEffect(() => {
    //     if (updateUserStatus === 'success') {
    //         navigate('/users')
    //     }
    //     console.log('status ', updateUserStatus)
    // }, [updateUserStatus])

    return (
        <Stack className="user" paddingX={2}>
            <Stack direction='row' alignItems='center' justifyContent='space-between' >
                <Typography variant='h5' fontWeight='bold'>Edit User</Typography>
                <Button variant='contained' onClick={() => navigate('/new-user')}>
                    Create
                </Button>
            </Stack>

            <Grid container spacing={2} marginTop={1}>
                <Grid item xs={12} md={4}>
                    <Card sx={{ padding: 2, }}>
                        {
                            loading === HTTP_STATUS.PENDING ? (
                                // true ? (
                                <div class="animate-pulse flex space-x-4 py-7">

                                    <div class="flex-1 space-y-6">
                                        <div className='flex flex-row items-center space-x-3'>
                                            <div class="rounded-full bg-gray-300 h-10 w-10"></div>
                                            <div class="h-2 bg-gray-300 rounded basis-1/4"></div>
                                        </div>
                                        <div class="h-2 bg-gray-300 rounded"></div>
                                        <div class="h-2 bg-gray-300 rounded"></div>
                                        <div class="h-2 bg-gray-300 rounded"></div>
                                        <div class="h-2 bg-gray-300 rounded"></div>
                                        {/* <div class="h-2 bg-gray-300 rounded"></div> */}
                                        {/* <div class="space-y-3">
                                                    <div class="grid grid-cols-3 gap-4">
                                                        <div class="h-2 bg-gray-300 rounded col-span-2"></div>
                                                        <div class="h-2 bg-gray-300 rounded col-span-1"></div>
                                                    </div>
                                                    <div class="h-2 bg-gray-300 rounded"></div>
                                                </div> */}
                                    </div>
                                </div>
                            ) : (
                                <CardContent style={{ flexGrow: 1 }}>
                                    <Stack direction='row' alignItems='center' spacing={2}>
                                        <Avatar alt="User" src={user?.profilePhoto} />
                                        <Stack>
                                            <Typography fontWeight='bold' variant='body2'>{user?.firstName} {user?.lastName}</Typography>
                                            <Typography variant='subtitle2' color="text.secondary">Papperoger User</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack sx={{ marginTop: 3 }} spacing={2}>
                                        <Typography variant='subtitle2' color="#A4A4A4" fontWeight='bold'>Contact Details</Typography>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <PhoneAndroid htmlColor="#A4A4A4" />
                                            <Typography variant='subtitle2' color="text.secondary" >{user?.mobilePhone}</Typography>
                                        </Stack>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <EmailOutlined htmlColor="#A4A4A4" />
                                            <Typography variant='subtitle2' color="text.secondary" >{user?.email}</Typography>
                                        </Stack>
                                        {/* <Stack direction='row' alignItems='center' spacing={1}>
                                <CalendarToday htmlColor="#A4A4A4"/>
                                <Typography variant='subtitle2' color="text.secondary" >10 February 2022</Typography>
                            </Stack> */}
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <LocationOn htmlColor="#A4A4A4" />
                                            <Typography variant='subtitle2' color="text.secondary" >{user?.address ? user.address : 'No Address'}</Typography>
                                        </Stack>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <CalendarToday htmlColor="#A4A4A4" />
                                            <Typography variant='subtitle2' color="text.secondary" >{user?.createdAt && format(new Date(user?.createdAt), 'dd MMMM yyy, p')}</Typography>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            )
                        }

                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card sx={{ padding: 2 }}>
                        {
                            loading === HTTP_STATUS.PENDING ? (
                            // true ? (
                                <div class="animate-pulse flex space-x-4 py-7">

                                    <div class="flex-1 space-y-10">
                                        <div className='flex flex-row items-center space-x-5'>
                                            <div class="h-4 bg-gray-300 rounded basis-3/4"></div>
                                            <div class="rounded-full bg-gray-300 h-32 w-32"></div>
                                        </div>
                                        <div class="h-4 bg-gray-300 rounded"></div>
                                        <div class="h-4 bg-gray-300 rounded"></div>
                                        <div class="h-4 bg-gray-300 rounded"></div>
                                        <div class="h-4 bg-gray-300 rounded"></div>
                                        {/* <div class="h-2 bg-gray-300 rounded"></div> */}
                                        {/* <div class="space-y-3">
                                                    <div class="grid grid-cols-3 gap-4">
                                                        <div class="h-2 bg-gray-300 rounded col-span-2"></div>
                                                        <div class="h-2 bg-gray-300 rounded col-span-1"></div>
                                                    </div>
                                                    <div class="h-2 bg-gray-300 rounded"></div>
                                                </div> */}
                                    </div>
                                </div>
                            ) : (
                                <CardContent style={{ flexGrow: 1 }}>
                                    <Typography variant='h6' fontWeight='bold'>Edit</Typography>
                                    <Box component='form' onSubmit={handleSubmit}>
                                        <Stack direction='row' justifyContent='space-between'>

                                            <Stack marginY={1}>

                                                <TextField id="first-name" sx={{ '& > :not(style)': { width: '40ch' }, }} name='firstName' defaultValue={user?.firstName} key={user?.firstName} margin='normal' label="First Name" variant="standard" />

                                                <TextField id="last-name" sx={{ '& > :not(style)': { width: '40ch' }, }} name='lastName' defaultValue={user?.lastName} key={user?.lastName} margin='normal' label="Last Name" variant="standard" />

                                                <TextField id="email" sx={{ '& > :not(style)': { width: '40ch' }, }} name='email' defaultValue={user?.email} margin='normal' key={user?.email} label="Email" variant="standard" />

                                                <TextField id="mobile" sx={{ '& > :not(style)': { width: '40ch' }, }} name='mobilePhone' defaultValue={user?.mobilePhone} key={user?.mobilePhone} margin='normal' label="Mobile No." variant="standard" />

                                                <TextField id="address" sx={{ '& > :not(style)': { width: '40ch' }, }} name='address' defaultValue={user?.address} key={user?.address} label="Address" margin='normal' variant="standard" />

                                            </Stack>

                                            <Stack justifyContent='space-between'>
                                                <Stack direction='row' alignItems='center' >
                                                    <Avatar
                                                        alt="user"
                                                        src={photo && typeof photo !== 'string' ? URL.createObjectURL(photo) : user?.profilePhoto}
                                                        // src={photo && typeof photo !== 'string' ? URL.createObjectURL(photo) : photo}
                                                        // src={photo}
                                                        sx={{ width: 120, height: 120 }}
                                                    />
                                                    <label htmlFor="icon-button-file">
                                                        <Input onChange={value => setPhoto(value.currentTarget.files[0])} accept="image/*" id="icon-button-file" type="file" />
                                                        {/* <Input onChange={value => console.log(URL.createObjectURL(value.currentTarget.files[0]))} name='profilePhoto' accept="image/*" id="icon-button-file" type="file" /> */}
                                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                                            <PhotoCamera />
                                                        </IconButton>
                                                    </label>
                                                </Stack>
                                                <Button type='submit' variant='contained'>Update</Button>
                                            </Stack>

                                        </Stack>
                                    </Box>
                                </CardContent>
                            )
                        }

                    </Card>
                </Grid>
            </Grid>
        </Stack >
    );
}
