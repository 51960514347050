import React, { useEffect } from 'react';
import { Typography, Grid, Box, Button, Stack, TextField, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createAgent } from '../../redux/agent'
import { HTTP_STATUS } from '../../utils/constants';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function NewAgent() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { createAgentStatus, loading } = useSelector(state => state.agent)

    //LOADING
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    //SNACKBAR
    const [snackOpen, setSnackOpen] = React.useState(false);

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackOpen(false);
    };

    //HANDLE SUBMIT
    const handleSubmit = async event => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        // console.log(data.get('firstName'))

        try {
            await dispatch(createAgent(data))
        } catch (error) {
            console.log(error)
        }
    }

    // useEffect(() => {
    //     if (createAgentStatus === 'success') {
    //         navigate('/agents')
    //     }
    // }, [createAgentStatus])

    return (
        <Stack flex={4} paddingX={2}>
            <Typography variant='h5' fontWeight='bold'>New Agent</Typography>
            <Box component='form' onSubmit={handleSubmit} sx={{ flexGrow: 1 }} marginTop={2}>
                <Grid container spacing={1}>
                    <Grid container item spacing={3}>
                        <Grid item xs={6}>
                            <TextField id="firstName" name='firstName' label="First Name" variant='outlined' fullWidth required />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="lastName" name='lastName' label="Last Name" variant='outlined' fullWidth required />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={6}>
                            <TextField id="email" name='email' label="Email" variant='outlined' fullWidth required />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="password" name='password' label="Password" type="password" variant='outlined' fullWidth required />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={6}>
                            <TextField id="mobile" name='mobilePhone' label="Mobile No." variant='outlined' fullWidth required />
                        </Grid>
                        {/* <Grid item xs={6}>
                            <TextField id="address" label="Address" variant='outlined' fullWidth />
                        </Grid> */}
                    </Grid>
                </Grid>
                <Button disabled={loading === HTTP_STATUS.PENDING ? true : false} type='submit' variant='contained' sx={{ marginTop: 3, width: 200 }}>
                    {
                        loading === HTTP_STATUS.PENDING &&
                        <div class="animate-spin h-5 w-5 mr-3 border-b-2 rounded-full border-blue-900" />
                    }
                    Create
                </Button>
            </Box>
            {
                loading === HTTP_STATUS.PENDING &&
                (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )
            }

            {
                loading === HTTP_STATUS.FULFILLED &&
                (
                    <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                            User has been added successfully!
                        </Alert>
                    </Snackbar>
                )
            }
        </Stack>
    );
}
