import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSupportCases, getSupportMessages, setSupportMessages, resetSupportMessages, updateSupportCaseAsResolved, setNewSupportCases } from '../../redux/message';
import { Input, Divider, Avatar, Button, Form, Empty, message as antdMessage, Modal } from 'antd';
import { MessageOutlined, CloseOutlined, UserOutlined, CheckCircleOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { SupportListItem } from '../../components/index'
import _ from "lodash";
import io from "socket.io-client";
import { format } from 'date-fns';
// import { format, subMonths } from 'date-fns'
// import { HTTP_STATUS } from '../../utils/constants'
const { REACT_APP_WEB_SOCKET_URL } = process.env
const socket = io.connect(REACT_APP_WEB_SOCKET_URL);

const CustomerSupport = () => {
    const dispatch = useDispatch()
    const lastMessageRef = useRef(null);
    const [form] = Form.useForm();
    const { adminProfile } = useSelector(state => state.admin)
    const { newSupportCases, supportMessages } = useSelector(state => state.message)
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [users, setUsers] = useState([]);
    const [typingStatus, setTypingStatus] = useState(false);
    const [isTyping, setIsTyping] = useState(false);

    const [room, setRoom] = useState('1');
    const [userName, setUserName] = useState('');
    const [supportCaseId, setSupportCaseId] = useState('');
    const [userProfilePhoto, setUserProfilePhoto] = useState('');
    const [userEmail, setUserEmail] = useState('');
    // const [searchText, setSearchText] = useState('');

    const [messageApi, contextHolder] = antdMessage.useMessage();

    const { Search } = Input;

    // useEffect(() => {
    //     if (searchText.trim() === '') {
    //         setTimeout(() => {
    //             dispatch(setNewSupportCasesFiltered(newSupportCases))
    //         }, 1000);
    //         // setFoodItems(props.foodItems)
    //     }
    // }, [searchText])

    const onSearch = (value) => {
        if (!value) return dispatch(getSupportCases())

        console.log(value)
        // setSearchText(value)

        try {
            if (value !== '') {
                let searchResult = [];
                let newSupportCasesList = newSupportCases
                for (let key in newSupportCasesList) {
                    //console.log(parsedRes[key].address);
                    // item.cuisine.map(cs => cs.name).join(' ').includes(name)
                    let userFullName = `${newSupportCasesList[key].user.firstName.toLowerCase()} ${newSupportCasesList[key].user.lastName.toLowerCase()}`;
                    // let restaurantName = restaurants[key].name.toLowerCase();
                    if ((userFullName.includes(value.toLowerCase())) && value.trim() !== '') {
                        searchResult.push(newSupportCasesList[key]);
                    }
                }
                // console.log(searchResult);
                dispatch(setNewSupportCases(searchResult))
                // props.onSetFoodItemsList(searchResult);
            }
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        socket.on('messageResponse', (data) => {
            dispatch(setSupportMessages(data))
            // console.log('message received')
        });
        // socket.on('messageResponse', (data) => setMessages(list => [...list, data]));
        socket.on('newUserResponse', (data) => setUsers(data));
        socket.on('typingResponse', (data) => setTypingStatus(data));
        // socket.on('userLeftSupportRes', (id) => {
        //     console.log('received userLeftSupportRes')
        //     // success()
        //     const data = {
        //         id,
        //         successMessage: success
        //     }
        //     dispatch(updateSupportCaseAsResolved(data))
        // });
    }, [socket]);

    const joinRoom = () => {
        if (room !== "") {
            socket.emit('joinRoom', room)
        }
    }

    useEffect(() => {

        // dispatch(resetSupportMessages())

        dispatch(getSupportCases())


        // socket.emit('newUser', { name: adminProfile.firstName, email: adminProfile.email, socketID: socket.id });
        // joinRoom()

        // return () => {
        //     console.log('out')
        //     socket.disconnect()
        // }
    }, [])

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    // const handleTyping = () => {
    //     socket.emit('typing', `${adminProfile.firstName} is typing`);
    // }

    useEffect(() => {
        // console.log('message', message)
        if (message) {
            socket.emit('typing', `${adminProfile.firstName} is typing...`);
            setIsTyping(true);
        } else {
            handleIsTyping()
        }
    }, [message])


    const handleIsTyping = _.debounce(() => {
        socket.emit('typing', null);
        // continually delays setting "isTyping" to false for 500ms until the user has stopped typing and the delay runs out
        setIsTyping(false);
    }, 500);


    const onFinish = async (values) => {


        // console.log('hello')

        // console.log('adminProfile:', adminProfile);

        // Send message to server { message: 'hello' }
        if (values.message.trim()) {
            setMessage(values.message)
            console.log('Success:', values);
            // console.log('Success:', values);
            const myMessage = {
                // room,
                text: values.message,
                user: {
                    _id: 2,
                    admin: adminProfile.id,
                    supportCaseId,
                    senderRole: 'admin',
                    name: 'Papperoger',
                    avatar: adminProfile.profilePhoto,
                }
                // adminId: adminProfile.id,
                // name: adminProfile.firstName,
                // email: adminProfile.email,
                // id: `${socket.id}${Math.random()}`,
                // socketID: socket.id,
            }
            await socket.emit('message', myMessage);
            // setMessages(list => [...list, myMessage])
        }
        // setMessage('');
        form.resetFields()
        handleIsTyping()
        // console.log(form.getFieldsValue())
    };

    const onFinishDebounced = _.debounce(onFinish, 1000, {
        leading: true,
        trailing: false
    });

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            console.log('enter press here! ')
        }
    }

    const handleGetSupportMessages = (id, name, userPhoto, email) => {
        // Join support room
        socket.emit('joinRoom', id)

        setUserName(name)
        setUserProfilePhoto(userPhoto)
        setUserEmail(email)
        setSupportCaseId(id)
        // console.log('hello')
        dispatch(getSupportMessages(id))
    }

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'The case has been resolved successfully!',
        });
    };

    const handleResolveCase = (id) => {
        // success()
        const data = {
            id,
            successMessage: success
        }
        dispatch(updateSupportCaseAsResolved(data))
    }

    // CONFIRM RESOLVED
    const { confirm } = Modal;
    const confirmResolve = (supportCaseId) => {
        confirm({
            okType: 'default',
            title: 'Do you want to resolve this case?',
            icon: <ExclamationCircleFilled />,
            content: 'The case will be removed from this page',
            onOk() {
                handleResolveCase(supportCaseId)
                // console.log('OK');
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    };


    return (
        <div className=' h-screen-90 xl:w-full' >
            {contextHolder}
            <div className='flex md:flex-row flex-col w-full py-3 xl:flex'>
                {/* left section */}
                <div className='flex flex-col md:w-2/5 md:h-screen-90 h-64 overflow-auto items-center'>
                    {/* <div className='items-center py-3 bg-gray-300'> */}
                    <Search
                        placeholder="Search users"
                        onSearch={onSearch}
                        style={{
                            width: '90%',
                        }}
                    />
                    <Divider plain style={{ color: 'gray' }}>NEW</Divider>
                    <div className='flex flex-col w-full px-5'>
                        {
                            newSupportCases?.length ?
                                newSupportCases?.map((supportCase) => (
                                    <div key={supportCase._id} className='flex flex-col cursor-pointer' onClick={() => handleGetSupportMessages(supportCase._id, `${supportCase.user.firstName} ${supportCase.user.lastName}`, supportCase.user.profilePhoto, supportCase.user.email)}>
                                        <SupportListItem supportCase={supportCase} confirmResolve={confirmResolve} />
                                        <Divider />
                                    </div>
                                ))
                                : (
                                    <Empty />
                                )
                        }
                    </div>
                    {/* <Divider plain style={{ color: 'gray' }}>RESOLVED</Divider>
                    <div className='flex flex-col w-full px-5'>
                        {
                            resSupportCases?.length ? resSupportCases?.map((supportCase) => (
                                <div onClick={() => handleGetSupportMessages(supportCase._id)} key={supportCase._id}>
                                    <SupportListItem supportCase={supportCase} />
                                    <Divider style={{marginTop: 10, marginBottom: 10}} />
                                </div>
                            )) : <Empty />

                        }
                    </div> */}
                    {/* </div> */}

                </div>
                {/* right section */}
                {
                    supportMessages.length ?

                        <div className='flex flex-col md:w-3/5 md:h-168 overflow-auto px-5 justify-between'>
                            <div>
                                <div className='flex md:flex-row flex-col w-full items-center justify-between space-x-3'>
                                    <div className='flex flex-row items-center space-x-3'>
                                        <Avatar size={80} src={userProfilePhoto} />
                                        <div className='flex flex-col'>
                                            <div className='font-semibold text-xl'>
                                                {userName}
                                            </div>
                                            <div className='text-gray-500'>
                                                {userEmail}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex flex-row items-center space-x-2 cursor-pointer' onClick={() => confirmResolve(supportCaseId)}>
                                        <CheckCircleOutlined style={{ color: 'green' }} />
                                        <div className='text-green-700'>Mark as Resolved</div>
                                    </div>

                                </div>
                                <Divider style={{ marginBottom: 0 }} />
                                <div className='self-center text-center text-gray-400 pt-1'>
                                    {format(new Date(), 'dd MMM yyyy').toUpperCase()}
                                </div>
                                <div className=' h-112 overflow-auto'>
                                    {/* admin message */}
                                    {/* <div className='flex flex-col my-3'>
                                <div className='pl-12 text-gray-400 text-xs my-1'>You 12:31 PM</div>
                                <div className='flex flex-row space-x-3'>
                                    <Avatar size='default' icon={<UserOutlined />} />
                                    <div className='bg-emerald-100 rounded-xl p-2'>Hello, how can I help you?</div>
                                </div>
                            </div> */}
                                    {/* user message */}
                                    {
                                        supportMessages?.length ?
                                            supportMessages.map(msg =>
                                                msg?.user?.admin === adminProfile?.id ?
                                                    (
                                                        <div className='flex flex-col my-3' key={msg?._id}>
                                                            <div className='pl-12 text-gray-400 text-xs my-1'>You {format(new Date(), 'p')}</div>
                                                            <div className='flex flex-row space-x-3'>
                                                                <Avatar size='default' src={adminProfile?.profilePhoto} />
                                                                <div className='bg-emerald-100 rounded-xl p-2'>{msg?.text}</div>
                                                            </div>
                                                        </div>

                                                    ) : (
                                                        <div className='flex flex-col my-3' key={msg?._id}>
                                                            <div className='pl-12 text-gray-400 text-xs my-1'>{userName} &nbsp; {format(new Date(msg?.createdAt), 'p')}</div>
                                                            <div className='flex flex-row space-x-3'>
                                                                <Avatar size='default' src={userProfilePhoto} />
                                                                <div className='bg-gray-100 rounded-xl p-2'>{msg?.text}</div>
                                                            </div>
                                                        </div>
                                                    )
                                            ) : <div className='flex h-full justify-center items-center'><Empty /></div>
                                    }
                                    <div ref={lastMessageRef} />
                                </div>
                            </div>
                            <div>
                                <div className=' justify-end h-3 text-sm text-gray-500'>
                                    {typingStatus}
                                </div>
                                {/* input and send section */}
                                <div className='flex flex-col mt-3'>
                                    {/* <div className='flex flex-row space-x-2'> */}
                                    <Form
                                        form={form}
                                        name="basic"
                                        // labelCol={{
                                        //     span: 8,
                                        // }}
                                        // wrapperCol={{
                                        //     span: 16,
                                        // }}
                                        style={{
                                            // backgroundColor: 'red'
                                            // maxWidth: '100%',
                                        }}
                                        // initialValues={{
                                        //     remember: true,
                                        // }}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        autoComplete="off"
                                    // onKeyPress={handleKeyPress}
                                    >
                                        <div className='flex flex-row space-x-2'>
                                            <Form.Item
                                                // label="Username"
                                                style={{
                                                    width: '100%'
                                                }}
                                                name="message"
                                            // rules={[
                                            //     {
                                            //         required: true,
                                            //         message: 'Please input your username!',
                                            //     },
                                            // ]}
                                            >
                                                <Input onChange={(e) => setMessage(e.target.value)} />
                                            </Form.Item>

                                            <Form.Item
                                            // wrapperCol={{
                                            //     offset: 8,
                                            //     span: 16,
                                            // }}
                                            >
                                                <Button htmlType="submit">
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                    {/* <Input
                                 placeholder="Write message"
                                 value={message}
                                 onChange={(e) => setMessage(e.target.value)}
                                />
                                <Button>SEND</Button> */}
                                    {/* </div> */}
                                </div>
                            </div>

                        </div> : null
                }
                {/* <FloatButton icon={chatEnabled ? <CloseOutlined /> : <MessageOutlined />} type="primary" onClick={() => setChatEnabled(!chatEnabled)} /> */}
            </div>
        </div>
    )
}

export default CustomerSupport