import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { PURGE } from "redux-persist";
import { message } from 'antd'
import axios from 'axios'
import { myHistory } from "../utils/history"
import { HTTP_STATUS } from '../utils/constants'
import storage from "../firebase"
import { ref, getDownloadURL, uploadBytes } from "firebase/storage"
const { REACT_APP_API_URL } = process.env


export const postLogin = createAsyncThunk(
    'admin/postLogin',
    async (adminData, thunkAPI) => {
        return await axios.post(`${REACT_APP_API_URL}login`, adminData)
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    // setLoginError(true)
                    sessionStorage.setItem('token', result.data.accessToken)
                    sessionStorage.setItem('refreshToken', result.data.refreshToken)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
            })
            .catch(err => console.log(err))
    }
)

export const updateAdmin = createAsyncThunk(
    //reducer name / method name
    'admin/updateAdmin',
    async (userdata, thunkAPI) => {


        // const updateAdminProfile = async (userdata, thunkAPI) => {
        const token = sessionStorage.getItem('token')
        return await axios.put(`${REACT_APP_API_URL}admin`, userdata, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                console.log(result)
                if (result.data.success) {
                    myHistory.replace('/my-account')
                    message.success('Your profile has been updated successfully!');
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
        // }

    }
)

export const updateProfilePhoto = createAsyncThunk(
    //reducer name / method name
    'admin/updateProfilePhoto',
    async (userdata, thunkAPI) => {

        // console.log('profilePhoto', profilePhoto)
        // const updateAdminProfile = async (userdata, thunkAPI) => {
        const token = sessionStorage.getItem('token')
        return await axios.put(`${REACT_APP_API_URL}admin/photo`, userdata, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                console.log(result)
                if (result.data.success) {
                    // myHistory.replace('/my-account')
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
        // }

    }
)

export const updateSupportPhone = createAsyncThunk(
    'admin/updateSupportPhone',
    async ({ supportPhone, successMessage, errorMessage }, thunkAPI) => {
        return await axios.put(`${REACT_APP_API_URL}supportPhone`, {supportPhone})
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    successMessage()
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    errorMessage()
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
            })
            .catch(err => console.log(err))
    }
)

export const getSupportPhone = createAsyncThunk(
    'admin/getSupportPhone',
    async (_, thunkAPI) => {
        return await axios.get(`${REACT_APP_API_URL}supportPhone`)
            .then(result => {
                // console.log(result)
                if (result.data.success) {

                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
            })
            .catch(err => console.log(err))
    }
)


const initialState = {
    status: '',
    loading: null,
    message: '',
    adminProfile: {},
    token: '',
    mobileOpen: false,
    supportPhone: '',
}

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        // increment: (state) => {
        //   state.value += 1
        // },
        // decrement: (state) => {
        //   state.value -= 1
        // },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setMobileOpen: (state, action) => {
            state.mobileOpen = action.payload
        },
        setMessage: (state, action) => {
            state.message = action.payload
        },
        clearLoading: (state) => {
            state.loading = null
        }
        // purgeData: (builder) => {
        //     builder.addCase(PURGE, (state) => {
        //         builder.removeAll(state);
        //     });
        // }
    },
    extraReducers: {
        [postLogin.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [postLogin.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.status = 'success';
            state.adminProfile = action.payload.profile;
            state.token = action.payload.accessToken;
        },
        [postLogin.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.status = 'failed';
            state.message = action.payload;
        },
        [updateAdmin.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [updateAdmin.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.status = 'success';
            state.adminProfile = action.payload.profile;
        },
        [updateAdmin.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.status = 'failed';
            state.message = action.payload;
        },
        [updateProfilePhoto.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [updateProfilePhoto.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.status = 'success';
        },
        [updateProfilePhoto.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.status = 'failed';
            state.message = action.payload;
        },        
        [updateSupportPhone.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [updateSupportPhone.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.supportPhone = action.payload.supportPhone;
        },
        [updateSupportPhone.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.status = 'failed';
            state.message = action.payload;
        }, 
        [getSupportPhone.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [getSupportPhone.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.supportPhone = action.payload.supportPhone;
        },
        [getSupportPhone.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.status = 'failed';
            state.message = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setToken, setMobileOpen, setMessage, clearLoading } = adminSlice.actions
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default adminSlice.reducer