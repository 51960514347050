import React, { useEffect, useState, useRef } from 'react';
import { Typography, Box, Button, Stack, Card, TextField, List, ListItem, ListItemText, Avatar, ButtonBase, Chip, Divider } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { colors } from '../../assets'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createUser, getUsers } from '../../redux/user'
import { getAgents } from '../../redux/agent'
import { getUserFeedbacks, getAgentFeedbacks } from '../../redux/message'
import { Loader } from '../../components'
import { HTTP_STATUS } from '../../utils/constants';
import { format } from 'date-fns'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Notification() {
    // const agentsRef = useRef()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { loading, userFeedbacks } = useSelector(state => state.message)

    const [selectedFeedback, setSelectedFeedback] = useState(null)

    useEffect(() => {
        dispatch(getUserFeedbacks())
    }, [])

    useEffect(() => {
        if (userFeedbacks.length) {
            setSelectedFeedback(userFeedbacks[0])
        }
    }, [userFeedbacks])

    return (
        <Stack flex={4} paddingX={2}>
            {/* <Loader /> */}
            <Typography sx={{ my: 2 }} variant='h5' fontWeight='bold'>User Feedbacks</Typography>

            <Card sx={{ flex: 1, mx: 2 }}>
                {
                    userFeedbacks.length ? (
                        <div className='flex flex-row'>
                            <div className='basis-2/5'>
                                <div className='flex flex-row'>
                                    <div>
                                        <div className='flex-row p-4'>
                                            <TextField id="search" name='search' variant='outlined' placeholder='Search feedbacks' fullWidth />
                                        </div>
                                        <Divider sx={{ my: 2 }} />
                                        <div className='flex w-full px-4'>
                                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                {
                                                    userFeedbacks.map((item) => (
                                                        <ButtonBase sx={{ width: '100%', flex: 1 }} component="div" onClick={() => setSelectedFeedback(item)}>
                                                            <ListItem >
                                                                <div className='flex flex-col w-full'>
                                                                    <ListItemText primary={item.name} secondary={item.email} />
                                                                    <div className='flex flex-row justify-between items-center'>
                                                                        <Chip label='User' color='default' sx={{ backgroundColor: 'rgb(3, 201, 215)', color: 'white' }} />
                                                                        <Typography fontSize='0.9rem'>{format(new Date(item.createdAt), 'dd MMMM yyyy p')}</Typography>
                                                                    </div>
                                                                </div>
                                                            </ListItem>
                                                        </ButtonBase>
                                                    ))
                                                }

                                            </List>
                                        </div>
                                    </div>
                                    <Divider orientation='vertical' flexItem />
                                </div>
                            </div>

                            <div className='basis-3/5'>
                                <div className='flex flex-row p-4 justify-between items-center'>
                                    <div className='flex flex-row gap-4'>
                                        <Avatar alt='profile photo' src={selectedFeedback?.user?.profilePhoto} className="topbarIconContainer" />
                                        <div>
                                            <Typography fontSize='0.9rem'>{selectedFeedback?.user?.firstName} {selectedFeedback?.user?.lastName}</Typography>
                                            <Typography fontSize='0.9rem' color='dimgray'>{selectedFeedback?.user?.email}</Typography>
                                        </div>
                                    </div>
                                    <Chip label='User' sx={{ backgroundColor: 'rgb(3, 201, 215)', color: 'white', mr: 2 }} />
                                </div>
                                <Divider sx={{ mx: 4 }} />
                                <Typography sx={{ m: 4 }}>{selectedFeedback?.message}</Typography>
                            </div>
                        </div>
                    ) : (
                        <div className='flex items-center justify-center'>
                            No User Feedbacks
                        </div>
                    )
                }

            </Card>

        </Stack>
    );
}
