import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { getAgents } from '../../redux/agent';
import { Input, Divider, Avatar, Badge } from 'antd';
import { MessageOutlined, CloseOutlined, UserOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { format, differenceInMinutes, differenceInHours, differenceInDays } from 'date-fns'
// import { HTTP_STATUS } from '../../utils/constants'


const SupportListItem = ({ supportCase, confirmResolve }) => {
    // const [chatEnabled, setChatEnabled] = useState(false)


    return (
        <div className='flex flex-col w-full h-full space-y-2'>
            {/* head */}
            <div className='flex flex-row justify-between'>
                <div className='flex flex-row space-x-3 items-center'>
                    <div className='flex'>
                        {/* avatar */}
                        <Avatar size="large" src={supportCase.user.profilePhoto} />
                    </div>
                    <div className='flex flex-col'>
                        <div className=' font-semibold'>
                            {`${supportCase.user.firstName} ${supportCase.user.lastName}`}
                        </div>
                        {/* <div className=' md:hidden text-gray-500'>
                            {supportCase.user.email}
                        </div> */}
                    </div>
                </div>

                <div className='flex flex-col'>
                    <div className='text-gray-400'>
                        {
                            differenceInMinutes(new Date(), new Date(supportCase.createdAt)) < 60 ?
                                differenceInMinutes(new Date(), new Date(supportCase.createdAt)) + ' min ago' :
                                differenceInHours(new Date(), new Date(supportCase.createdAt)) < 24 ?
                                    differenceInHours(new Date(), new Date(supportCase.createdAt)) + ' hours ago' :
                                    differenceInDays(new Date(), new Date(supportCase.createdAt)) + ' days ago'
                        }
                    </div>
                    <div className='flex flex-row space-x-2 mt-1 items-center' onClick={() => confirmResolve(supportCase._id)}>
                        <CheckCircleOutlined style={{ color: 'green' }} />
                        <div className='text-sm text-green-700'>Resolve</div>
                    </div>
                </div>
            </div>
            {/* message */}
            {/* <div className='flex flex-row justify-between'>
                <div>Hello, can you help me please?</div>
                <Badge count={1} />
            </div> */}
        </div>
    )
}

export default SupportListItem