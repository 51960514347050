import React, { useState, useEffect, useLayoutEffect } from 'react';
import { images } from '../../assets'
// import { SuccessMessage } from '../../components'
import { message } from 'antd'
import { DataGrid } from '@mui/x-data-grid';
import { Stack, Avatar, IconButton, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Chip } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getCoupons, setSelectedCoupon, deleteCoupon, setCrudOperation } from '../../redux/coupon'
// import { getUsers, setSelectedUserId, setUpdateUserStatus, setCreateUserStatus, deleteUser } from '../../redux/user'
import { useNavigate } from 'react-router-dom'
import { HTTP_STATUS } from '../../utils/constants'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CouponList() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { coupons, loading } = useSelector(state => state.coupon)
  const [messageApi, contextHolder] = message.useMessage();


  const [couponId, setCouponId] = useState(null);

  // Dialog Section
  const [open, setOpen] = useState(false);

  const successMessage = (msg) => {
    messageApi.open({
      type: 'success',
      content: msg,
    });
  };

  const errorMessage = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect Section
  useLayoutEffect(() => {
    // dispatch(setUpdateUserStatus())
    // dispatch(setCreateUserStatus())

    dispatch(getCoupons())
    // console.log(users)

  }, [])


  const handleDialog = id => {
    console.log(id)
    setCouponId(id)
    handleClickOpen()
  }

  const handleDelete = async () => {

    try {
      // const formData = new FormData()
      // formData.append('id', userId)

      const data = {
        couponId,
        successMessage,
        errorMessage,
      }

      dispatch(deleteCoupon(data))
      handleClose()
    } catch (error) {
      console.log(error)
      // throw new Error(error)
    }


    // setData(users.filter(item => item.origId !== origId))
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'code', headerName: 'Code', width: 100 },
    // { field: 'lastName', headerName: 'Last name', width: 100 },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 140,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
    {
      field: 'discount',
      headerName: 'Discount',
      // type: 'number',
      width: 90,
    },
    {
      field: 'discountType',
      headerName: 'Type',
      // type: 'number',
      width: 90,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      // type: 'number',
      width: 90,
    },
    {
      field: 'validFrom',
      headerName: 'Valid From',
      // type: 'number',
      width: 150,
    },
    {
      field: 'validTo',
      headerName: 'Valid To',
      // type: 'number',
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      // type: 'number',
      width: 90,
      renderCell: params => (
        <Chip label={params.row.status ? 'Active' : 'Inactive'} color={params.row.status ? 'success' : 'error'} />
      )
    },
    // {
    //   field: 'transactions',
    //   headerName: 'Total transactions',
    //   // type: 'number',
    //   width: 150,
    // },
    {
      field: 'action', headerName: 'Action', width: 150, renderCell: params => (
        <Stack direction="row" spacing="2" alignItems='center'>
          <Link to={"/coupon/" + params.row.id}>
            <IconButton onClick={() => {
              dispatch(setCrudOperation('update'))
              dispatch(setSelectedCoupon(params.row))
            }} color="success" aria-label="edit coupon" component="span">
              <Edit />
            </IconButton>
          </Link>
          <IconButton onClick={() => handleDialog(params.row.origId)} color="error" aria-label="delete user" component="span">
            <Delete />
          </IconButton>
        </Stack>

      )
    },
  ];

  return (
    <div className='basis-full p-4'>
      {contextHolder}
      <Stack direction='row' alignItems='center' justifyContent='space-between' paddingY={2}>
        <Typography variant='h5' fontWeight='bold'>Coupon List</Typography>
        <Button variant='contained' onClick={() => {
          dispatch(setCrudOperation('create'))
          navigate('/coupon')
        }}>
          Create
        </Button>
      </Stack>
      {
        loading === HTTP_STATUS.PENDING ? (
          // true ? (
          <div class="animate-pulse flex p-7">

            <div class="flex-1 space-y-10">
              <div class="h-5 bg-gray-300 rounded w-1/2"></div>
              <div class="h-5 bg-gray-300 rounded"></div>
              <div class="h-5 bg-gray-300 rounded"></div>
              <div class="h-5 bg-gray-300 rounded"></div>
              <div class="h-5 bg-gray-300 rounded"></div>
              {/* <div class="h-2 bg-gray-300 rounded"></div> */}
              {/* <div class="space-y-3">
                                  <div class="grid grid-cols-3 gap-4">
                                      <div class="h-2 bg-gray-300 rounded col-span-2"></div>
                                      <div class="h-2 bg-gray-300 rounded col-span-1"></div>
                                  </div>
                                  <div class="h-2 bg-gray-300 rounded"></div>
                              </div> */}
            </div>
          </div>
        ) : (
          <DataGrid
            getRowId={row => row?.id}
            rows={coupons}
            columns={columns}
            pageSize={8}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            autoPageSize
            autoHeight={true}
          />
        )
      }



      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirm delete user?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this coupon?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDelete}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
