import React, { useEffect } from 'react';
import './topbar.css';
import { images, colors } from '../../assets'
import { notification } from 'antd';
// import { MenuOutlined } from '@material-ui/icons'
import { Badge, Avatar, Stack, MenuItem, Popover, Menu, Typography, Chip, List, ListItem, Divider, ListItemText, ListItemAvatar, Button, Box, IconButton } from '@mui/material';
import { NotificationsNone, Language, Settings, MenuOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'
import { persistor } from "../../redux/store";
import { useSelector, useDispatch } from 'react-redux';
import { setMobileOpen } from '../../redux/admin';
import { getLastFoodOrders } from '../../redux/foodOrder';
import io from "socket.io-client";


const { REACT_APP_WEB_SOCKET_URL } = process.env

// const socket = io(REACT_APP_WEB_SOCKET_URL, {
//   withCredentials: true,
//   extraHeaders: {
//     "my-custom-header": "abcd"
//   }
// });

const socket = io.connect(REACT_APP_WEB_SOCKET_URL);

const drawerWidth = 240;

export default function Topbar() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { adminProfile, mobileOpen } = useSelector(state => state.admin)
    const { lastFoodOrders } = useSelector(state => state.foodOrder)

    //Profile Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //POPOVER
    const [notiAnchorEl, setNotiAnchorEl] = React.useState(null);

    const handleNotiClick = (event) => {
        setNotiAnchorEl(event.currentTarget);
    };

    const handleNotiClose = () => {
        setNotiAnchorEl(null);
    };
    const notiOpen = Boolean(notiAnchorEl);
    const id = notiOpen ? 'simple-popover' : undefined;

    //LOGOUT SECTION
    const handleLogout = () => {
        sessionStorage.removeItem('token')
        persistor.purge()
        window.location.reload(false)
    }

    useEffect(() => {
        dispatch(getLastFoodOrders())
        socket.emit('admin') // Start listening
        socket.on('newFoodOrder', foodOrder => {
            notification.open({
                message: 'We have a new food order!',
                description:
                    `A new order has been placed in Papperoger Eats. Order # ${foodOrder.orderNo}`,
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
            console.log('Order ID from Socket IO: ', foodOrder)
        })

        socket.on('messageResponse', (data) => console.log(data));

    }, [])

    // const handleDisconnectSocket = () => {
    //     socket.disconnect()
    // }

    // const openNotification = () => {
    //     notification.open({
    //         message: 'We have a new food order!',
    //         description:
    //             'Mustafa has ordered 2 x Nasi Ayam from Papperoger Eats.',
    //         onClick: () => {
    //             console.log('Notification Clicked!');
    //         },
    //     });
    // };

    return (
        <nav className="w-full flex justify-between items-center py-4 md:px-8 px-4 top-0 h-24 bg-gray-100">

            <Stack direction='row' alignItems='center' spacing={2}
            >
                {/* <IconButton
                    color='inherit'
                    aria-label="open drawer"
                    edge="start"
                    // sx={{display: 'hidden'}}
                    className='md:hidden'
                    // onClick={() => dispatch(setMobileOpen(true))}
                    // sx={{
                    //     display: { sm: 'block', md: 'none' },
                    //     '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    // }}
                > */}
                <div className='xl:hidden flex cursor-pointer' onClick={() => dispatch(setMobileOpen(true))}>
                    <MenuOutlined className='text-gray-600' style={{ fontSize: 30 }} />
                </div>
                {/* </IconButton> */}

                {
                    !mobileOpen && (
                        <>
                            <img src={images.logo} alt="Logo" className='object-contain w-12' />
                            <span className='text-2xl text-blue-900' >PAPPEROGER</span>
                        </>
                    )
                }

            </Stack>

            <div className='flex flex-row items-center justify-center gap-3'>
                <div className='cursor-pointer' >
                    <div onClick={handleNotiClick}>
                        <Badge badgeContent={2} color="primary">
                            <NotificationsNone color="action" />
                        </Badge>
                    </div>
                    <Popover
                        id={id}
                        open={notiOpen}
                        anchorEl={notiAnchorEl}
                        onClose={handleNotiClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <Stack sx={{ p: 3 }}>
                            <Stack direction='row' alignItems='center' spacing={2} width={300} >
                                <Typography >Notifications</Typography>
                                <Chip label="2 new" style={{ borderRadius: 8, backgroundColor: colors.secondary, color: colors.white }} />
                            </Stack>
                            <Stack>
                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                                    {
                                        lastFoodOrders.slice(-2).map((item, index) => (
                                            <div>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemAvatar>
                                                        <Avatar alt="Papperoger Eats" src={images.logo} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary="New Food Order"
                                                        secondary={
                                                            <React.Fragment>
                                                                <Typography
                                                                    sx={{ display: 'inline' }}
                                                                    component="span"
                                                                    variant="body2"
                                                                    color="text.primary"
                                                                >
                                                                    Papperoger Eats
                                                                </Typography>
                                                                {` - ${item.user.firstName} has placed a new order in Papperoger Eats. Order Number ${item.orderNo}`}
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </ListItem>
                                                {
                                                    index === 0 && (
                                                        <Divider variant="inset" component="li" />
                                                    )
                                                }
                                            </div>
                                        ))
                                    }

                                    {/* <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="Brunch this weekend?"
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        Ali Connors
                                                    </Typography>
                                                    {" — I'll be in your neighborhood doing errands this…"}
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="Summer BBQ"
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        to Scott, Alex, Jennifer
                                                    </Typography>
                                                    {" — Wish I could come, but I'm out of town this…"}
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem> */}
                                </List>
                                <Button variant='contained' color='info' onClick={() => {
                                    handleNotiClose()
                                    navigate('/food-orders')
                                }} >See all orders</Button>
                            </Stack>
                        </Stack>
                    </Popover>
                </div>
                <div className='flex-row flex items-center gap-2'>
                    <div onClick={handleClick}>
                        <Avatar alt="Mustafa Rabi" src={adminProfile.profilePhoto} className="topbarIconContainer" />
                    </div>
                    <Typography className='hidden md:flex' color='GrayText'>Hello, <Typography component='span' color='GrayText' fontWeight='bold'>{adminProfile.firstName}!</Typography></Typography>
                </div>
                {/* <div onClick={openNotification} className='cursor-pointer'>
                    Disconnect
                </div> */}
            </div>




            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 29,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                sx={{ width: 200 }}
            >
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                <MenuItem onClick={() => {
                    handleClose()
                    navigate('my-account')
                }}>My account</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>




            {/* <Button variant="contained">Hello World</Button> */}
        </nav>
    );
}

