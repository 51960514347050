export default {
    primary:'#358597',
    secondary:'#F4A896',
    third:'#2091ab',
    white: 'white',
    whiteOpacity: (opacity) => `rgba(255,255,255,${opacity})`,
    greyOpacity: (opacity) => `rgba(236, 236, 236,${opacity})`,
    blackOpacity: (opacity) => `rgba(0, 0, 0, ${opacity})`,
    black: 'rgb(0,0,0)',
    borderGrey: '#cfcfcf',
    yellow: '#FFEC01',
    red: '#f00',
    redOrange: 'rgb(198,83,88)',
    electricViolet: '#cf07d9',
    greyBackground: '#F6F6F6',
    redViolet: '#C5098D',
    dimGray: '#707070',
    silver: '#C2C2C2',
    Alabaster: '#F9F9F9',
    brightSunColor: '#FFEA30',
    discountRed: '#FF2C2C',
    sizeCircleBorder: (opacity) => `rgba(112, 112, 112, ${opacity})`,
    blackRussian: '#1E1E22',
    darkBlueOpacity: (opacity) => `rgba(4, 22, 36, ${opacity})`,
    oxfordBlue: (opacity) => `rgba(38, 50, 56, ${opacity})`,
    lightPink: '#FFC3ED',
    blueChalk: 'rgb(225,242,255)',
    whiteSmoke: '#F3F3F3',
    lightGrey: 'rgb(235,235,235)',
    medvioletRed: (opacity) => `rgba(197, 9, 141, ${opacity})`,
    palePurple: '#F2E3FA',
    redVioletOpacity: '#C5098DA3',
    deepSkyBlue: '#00A3F2',
    green: 'rgb(44,169,65)',
    altoGray: '#CFCFCF',
    dodgerBlue: '#0f243e',
    search:'rgb(25,67,107)',
    purple:'rgb(112,108,169)',
    tint:'rgb(143,155,179)',
    lightRed: '#ffcccb',
    papperogerPrimary: '#1b1464',
    papperogerSecondary: '#f15a24',
  };
  