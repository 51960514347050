import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { myHistory } from "../utils/history"
import { HTTP_STATUS } from '../utils/constants'
const { REACT_APP_API_URL } = process.env

export const getAgents = createAsyncThunk(
    //reducer name / method name
    'agent/getAgents',
    async (thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.get(`${REACT_APP_API_URL}agents`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    // console.log('agentsThisMonth', result.data.agentsThisMonth)
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const getSelectedAgent = createAsyncThunk(
    //reducer name / method name
    'agent/getSelectedAgent',
    async (selectedAgentId, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.get(`${REACT_APP_API_URL}agent`, {
            params: {
                id: selectedAgentId
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                // console.log('res', result)
                if (result.data.success) {
                    console.log(result.data.agent)
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const updateAgent = createAsyncThunk(
    //reducer name / method name
    'agent/updateAgent',
    async (userdata, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.put(`${REACT_APP_API_URL}agent`, userdata, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    myHistory.replace('/agents')
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const deleteAgent = createAsyncThunk(
    //reducer name / method name
    'agent/deleteAgent',
    async (id, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.delete(`${REACT_APP_API_URL}agent`, {
            params: {
                id
            }
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                console.log(result.data)
                if (result.data.success) {
                    // console.log('thunkAPI', thunkAPI)
                    // dispatch(getUsers())
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const createAgent = createAsyncThunk(
    //reducer name / method name
    'agent/createAgent',
    async (agentData, thunkAPI) => {
        // const {email, password} = userData
        return await axios.post(`${REACT_APP_API_URL}agent/register`, agentData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    myHistory.replace(`/agents`)
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

const mapAgents = (agents) => {
    return agents.map((item, i) => {
        const newData = {
            id: i + 1,
            origId: item._id,
            agent: item.firstName + ' ' + item.lastName,
            email: item.email,
            mobile: item.mobilePhone,
            agentType: item.agentType,
            status: item.status,
            profilePhoto: item.profilePhoto,
        }
        return newData
    });
}

const initialState = {
    // status: '',
    loading: null,
    createAgentStatus: '',
    updateAgentStatus: '',
    deleteAgentStatus: '',
    selectedAgentId: '',
    agents: [],
    agent: {},
    message: '',
    agentsThisMonth: '',
    agentsSubOne: '',
    agentsSubTwo: '',
    agentsSubThree: '',
    agentsSubFour: '',
    agentsSubFive: '',
}

export const agentSlice = createSlice({
    name: 'agent',
    initialState,
    reducers: {
        setCreateAgentStatus: (state, action) => {
            state.createAgentStatus = ''
        },
        setSelectedAgentId: (state, action) => {
            state.selectedAgentId = action.payload
        },
        setUpdateAgentStatus: (state, action) => {
            state.updateAgentStatus = ''
        },
        clearAgentLoading: (state) => {
            state.loading = null
        }
    },
    extraReducers: {
        [getAgents.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [getAgents.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.status = 'success';
            state.agentsThisMonth = action.payload.agentsThisMonth.length
            state.agentsSubOne = action.payload.agentsSubOne.length
            state.agentsSubTwo = action.payload.agentsSubTwo.length
            state.agentsSubThree = action.payload.agentsSubThree.length
            state.agentsSubFour = action.payload.agentsSubFour.length
            state.agentsSubFive = action.payload.agentsSubFive.length
            state.agents = mapAgents(action.payload.agents)
            // state.agents = action.payload.agents.map((item, i) => {
            //     const newData = {
            //         id: i + 1,
            //         origId: item._id,
            //         agent: item.firstName + ' ' + item.lastName,
            //         email: item.email,
            //         mobile: item.mobilePhone,
            //         agentType: item.agentType,
            //         status: item.status,
            //         profilePhoto: item.profilePhoto,
            //     }
            //     return newData
            // });
        },
        [getAgents.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.status = 'failed';
            state.message = action.payload;
        },
        [createAgent.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.createAgentStatus = 'loading'
        },
        [createAgent.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.createAgentStatus = 'success';
        },
        [createAgent.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.createAgentStatus = 'failed';
            state.message = action.payload;
        },
        [getSelectedAgent.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [getSelectedAgent.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.status = 'success'
            state.agent = action.payload.agent;
        },
        [getSelectedAgent.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.status = 'failed';
            state.message = action.payload;
        },
        [updateAgent.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.updateAgentStatus = 'loading'
        },
        [updateAgent.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.updateAgentStatus = 'success';
            // state.users = action.payload.user;
        },
        [updateAgent.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.updateAgentStatus = 'failed';
            state.message = action.payload;
        },
        [deleteAgent.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.deleteAgentStatus = 'loading'
        },
        [deleteAgent.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.agents = mapAgents(action.payload.agents)
            // state.deleteAgentStatus = 'success';
            // state.users = action.payload.user;
        },
        [deleteAgent.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.deleteAgentStatus = 'failed';
            state.message = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setCreateAgentStatus, setSelectedAgentId, setUpdateAgentStatus, clearAgentLoading } = agentSlice.actions
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default agentSlice.reducer