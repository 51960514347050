import React, { useState, useEffect, useLayoutEffect } from 'react';
import './userList.css'
import { images } from '../../assets'
import { DataGrid } from '@mui/x-data-grid';
import { Stack, Avatar, IconButton, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getUsers, setSelectedUserId, setUpdateUserStatus, setCreateUserStatus, deleteUser } from '../../redux/user'
import { useNavigate } from 'react-router-dom'
import { HTTP_STATUS } from '../../utils/constants'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserList() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { users, deleteUserStatus, status, loading } = useSelector(state => state.user)

  const [userId, setUserId] = useState(null);

  // Dialog Section
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect Section
  useLayoutEffect(() => {
    dispatch(setUpdateUserStatus())
    dispatch(setCreateUserStatus())

    dispatch(getUsers())
    console.log(users)

  }, [])

  // useEffect Section
  // useEffect(() => {
  //   console.log('status', status)

  // }, [status])

  // useEffect(() => {
  //   if (loading === HTTP_STATUS.FULFILLED) {
  //     console.log('success')
  //     dispatch(getUsers())
  //   }
  // }, [loading])

  // const [data, setData] = useState(users)
  // const [data, setData] = useState(users?.map((item, i) => {
  //   const newData = {
  //     id: i+1,
  //     user: item.firstName+item.lastName,
  //     email: item.email,
  //     mobile: item.mobilePhone,
  //     transactions: 'RM244',
  //   }
  //   return newData
  // }))
  // console.log('users', users)
  // const [data, setData] = useState([
  //   { id: 1, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 2, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 3, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 4, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 5, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 6, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 7, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 8, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 9, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 10, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 11, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  // ])

  const handleDialog = id => {
    console.log(id)
    setUserId(id)
    handleClickOpen()
  }

  const handleDelete = async () => {

    try {
      // const formData = new FormData()
      // formData.append('id', userId)

      dispatch(deleteUser(userId))
      handleClose()
    } catch (error) {
      console.log(error)
      // throw new Error(error)
    }


    // setData(users.filter(item => item.origId !== origId))
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'user', headerName: 'User', width: 200, renderCell: (params) => (
        <Stack direction='row' alignItems='center' spacing={1}>
          <Avatar alt="Mustafa Rabi" src={params.row.profilePhoto} key={params.row.profilePhoto} />
          <span>
            {params.row.user}
          </span>
        </Stack>
      )
    },
    // { field: 'lastName', headerName: 'Last name', width: 100 },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 140,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
    {
      field: 'email',
      headerName: 'Email',
      // type: 'number',
      width: 200,
    },
    {
      field: 'mobile',
      headerName: 'Mobile No.',
      // type: 'number',
      width: 150,
    },
    // {
    //   field: 'transactions',
    //   headerName: 'Total transactions',
    //   // type: 'number',
    //   width: 150,
    // },
    {
      field: 'action', headerName: 'Action', width: 150, renderCell: params => (
        <Stack direction="row" spacing="2" alignItems='center'>
          <Link to={"/user/" + params.row.id}>
            <IconButton onClick={() => dispatch(setSelectedUserId(params.row.origId))} color="success" aria-label="edit user" component="span">
              <Edit />
            </IconButton>
          </Link>
          <IconButton onClick={() => handleDialog(params.row.origId)} color="error" aria-label="delete user" component="span">
            <Delete />
          </IconButton>
        </Stack>

      )
    },
  ];

  return (
    <div className='basis-full p-4'>
      <Stack direction='row' alignItems='center' justifyContent='space-between' paddingY={2}>
        <Typography variant='h5' fontWeight='bold'>User List</Typography>
        <Button variant='contained' onClick={() => navigate('/new-user')}>
          Create
        </Button>
      </Stack>
      {
        loading === HTTP_STATUS.PENDING ? (
          // true ? (
          <div class="animate-pulse flex p-7">

            <div class="flex-1 space-y-10">
              <div class="h-5 bg-gray-300 rounded w-1/2"></div>
              <div class="h-5 bg-gray-300 rounded"></div>
              <div class="h-5 bg-gray-300 rounded"></div>
              <div class="h-5 bg-gray-300 rounded"></div>
              <div class="h-5 bg-gray-300 rounded"></div>
              {/* <div class="h-2 bg-gray-300 rounded"></div> */}
              {/* <div class="space-y-3">
                                  <div class="grid grid-cols-3 gap-4">
                                      <div class="h-2 bg-gray-300 rounded col-span-2"></div>
                                      <div class="h-2 bg-gray-300 rounded col-span-1"></div>
                                  </div>
                                  <div class="h-2 bg-gray-300 rounded"></div>
                              </div> */}
            </div>
          </div>
        ) : (
          <DataGrid
            rows={users}
            columns={columns}
            pageSize={8}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            autoPageSize
            autoHeight={true}
          />
        )
      }



      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirm delete user?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDelete}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
