// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import React from 'react';
import './index.css'
import ReactDOM from 'react-dom';
import App from './App';
import { store, persistor } from './redux/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
// import { HistoryRouter } from "./utils/HistoryRouter"
// import { myHistory } from "./utils/history"
// import { persistStore } from 'redux-persist'
import Favicon from 'react-favicon'
import { images } from './assets'
import axios from 'axios';
const { REACT_APP_API_URL } = process.env
// import { useNavigate } from "react-router-dom";
// let navigate = useNavigate();


// AXIOS SECTION
// const api = axios.create({
//   baseURL: 'http://localhost:1337',
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// const apiRefresh = axios.create({
//   baseURL: 'http://localhost:1337',
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// api.interceptors.request.use(async config => {
//   console.log(config)
//   const token = sessionStorage.getItem('token');
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

axios.interceptors.request.use(
  request => {
    const token = sessionStorage.getItem('token');
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  },
  error => {
    return Promise.reject(error)
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async error => {
    const originalRequest = error.config;
    // console.log('mustaaa', error.response)

    if (error.response.status === 401 && originalRequest.url ===
      `${REACT_APP_API_URL}token`) {
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('refreshToken')
      persistor.purge()
      window.location.reload(false)
      return Promise.reject(error);
    }

    if (error.response.status === 403 && !originalRequest._retry) {
      // console.log('inside')
      originalRequest._retry = true;

      await refreshToken();
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

const refreshToken = async (error) => {
  return new Promise((resolve, reject) => {
    try {
      const refreshToken = sessionStorage.getItem('refreshToken')
      axios
        .get(
          `${REACT_APP_API_URL}token`,
          {
            params: {
              refreshToken
            }
          }
        )
        .then(async (res) => {
          console.log('New Token: ', res.data)
          sessionStorage.setItem('token', res.data.accessToken)
          return resolve(res);
        })
        .catch((err) => {
          console.log(err)
          return reject(error);
        });
    } catch (err) {
      return reject(err);
    }
  })
};


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Favicon url={images.logo} />
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
