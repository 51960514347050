import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {format} from 'date-fns'
// import { SuccessMessage } from '../components'
import { HTTP_STATUS } from '../utils/constants'
import { myHistory } from "../utils/history"
const { REACT_APP_API_URL } = process.env

export const createCoupon = createAsyncThunk(
    //reducer name / method name
    'coupon/createCoupon',
    async ({values, successMessage, errorMessage}, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.post(`${REACT_APP_API_URL}coupon`, values, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    successMessage(result.data.message)
                    myHistory.replace('/coupons')

                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    errorMessage(result.data.message)
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
            })
            .catch(err => console.log(err))
    }
)

export const updateCoupon = createAsyncThunk(
    //reducer name / method name
    'coupon/updateCoupon',
    async ({values, successMessage, errorMessage}, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.put(`${REACT_APP_API_URL}coupon`, values, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    console.log('message', result.data.message)
                    myHistory.replace('/coupons')
                    // successMessage(result.data.message)

                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    errorMessage(result.data.message)
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
            })
            .catch(err => console.log(err))
    }
)

export const getCoupons = createAsyncThunk(
    //reducer name / method name
    'coupon/getCoupons',
    async (thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.get(`${REACT_APP_API_URL}coupons`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    // myHistory.replace('/coupons')
                    
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
            })
            .catch(err => console.log(err))
    }
)

export const deleteCoupon = createAsyncThunk(
    //reducer name / method name
    'coupon/deleteCoupon',
    async ({couponId, successMessage, errorMessage}, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.delete(`${REACT_APP_API_URL}coupon`, {
            params: {
                id: couponId
            }
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                console.log(result.data)
                if (result.data.success) {
                    successMessage(result.data.message)
                    // console.log('thunkAPI', thunkAPI)
                    // dispatch(getUsers())
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    errorMessage(result.data.message)
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

const mapCoupons = (coupons) => {
    // console.log(users)
    const newCoupons = coupons.map((item, i) => {
        const newData = {
            id: i + 1,
            origId: item._id,
            code: item.code,
            discount: item.discount,
            discountType: item.discountType,
            status: item.status,
            quantity: item.quantity,
            validFrom: format(new Date(item.validFrom), 'dd-MM-yyyy p'),
            validTo: format(new Date(item.validTo), 'dd-MM-yyyy p'),
        }
        return newData
    });
    return newCoupons
}

const initialState = {
    message: '',
    loading: null,
    coupons: [],
    coupon: {},
    crudOperation: 'create'
}

export const couponSlice = createSlice({
    name: 'coupon',
    initialState,
    reducers: {
        // clearRatingLoading: (state) => {
        //     state.loading = null
        // }
        setSelectedCoupon: (state, action) => {
            state.coupon = action.payload
        },
        setCrudOperation: (state, action) => {
            state.crudOperation = action.payload
        },
    },
    extraReducers: {
        [createCoupon.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.getAgentRatingsStatus = 'loading'
        },
        [createCoupon.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.getAgentRatingsStatus = 'success'
            // state.coupons = action.payload.coupons;
        },
        [createCoupon.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.getAgentRatingsStatus = 'failed';
            state.message = action.payload;
        },  
        [updateCoupon.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.getAgentRatingsStatus = 'loading'
        },
        [updateCoupon.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.getAgentRatingsStatus = 'success'
            // state.coupons = action.payload.coupons;
        },
        [updateCoupon.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.getAgentRatingsStatus = 'failed';
            state.message = action.payload;
        },  
        [getCoupons.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.getAgentRatingsStatus = 'loading'
        },
        [getCoupons.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.getAgentRatingsStatus = 'success'
            state.coupons = mapCoupons(action.payload.coupons)
            // state.coupons = action.payload.coupons;
        },
        [getCoupons.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.getAgentRatingsStatus = 'failed';
            state.message = action.payload;
        },
        [deleteCoupon.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.getAgentRatingsStatus = 'loading'
        },
        [deleteCoupon.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.getAgentRatingsStatus = 'success'
            state.coupons = mapCoupons(action.payload.coupons)
            // state.coupons = action.payload.coupons;
        },
        [deleteCoupon.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.getAgentRatingsStatus = 'failed';
            state.message = action.payload;
        },
  },
})

// Action creators are generated for each case reducer function
export const { setSelectedCoupon, setCrudOperation } = couponSlice.actions

export default couponSlice.reducer