import React, { useEffect } from 'react';
import './widgetSm.css'
import { images } from '../../assets'
import { Card, CardActions, CardContent, Typography, Button, Grid, List, IconButton, ListItemText, ListItem, ListItemAvatar, Avatar } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { getLastUsers, setGetLastUsersStatus, setSelectedUserId } from '../../redux/user';
import { Link } from 'react-router-dom'
import { HTTP_STATUS } from '../../utils/constants'

export default function WidgetSm() {
  const dispatch = useDispatch()
  const { lastUsers, getLastUsersStatus, loading } = useSelector(state => state.user)

  useEffect(() => {
    dispatch(getLastUsers())

    return () => dispatch(setGetLastUsersStatus())
  }, [])

  return (
    <div className='basis-1/2'>
      <Card style={{ flex: 1 }}>
        {
          loading === HTTP_STATUS.PENDING ? (
          // true ? (
            <div class="animate-pulse flex p-7">

              <div class="flex-1 space-y-10">
                <div class="h-4 bg-gray-300 rounded w-1/2"></div>
                <div class="h-4 bg-gray-300 rounded"></div>
                <div class="h-4 bg-gray-300 rounded"></div>
                <div class="h-4 bg-gray-300 rounded"></div>
              </div>
            </div>
          ) : (
            <CardContent style={{ flexGrow: 1 }}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                New Users
              </Typography>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {
                  lastUsers?.map(item => (
                    <ListItem sx={{ paddingY: 0.65 }}>
                      <ListItemAvatar>
                        <Avatar alt='profile photo' src={images.businessman} className="topbarIconContainer" />
                      </ListItemAvatar>
                      <ListItemText primary={`${item.firstName} ${item.lastName}`} secondary={item.email} />
                      <Link to={"/user/" + item._id}>
                        <IconButton onClick={() => dispatch(setSelectedUserId(item._id))} color="primary" aria-label="display user" component="span">
                          <Visibility />
                        </IconButton>
                      </Link>
                    </ListItem>
                  ))
                }
              </List>
            </CardContent>
          )
        }

      </Card>
    </div>
  );
}
