import React, { useEffect, useState, useRef } from 'react';
import { Typography, Grid, Box, Button, Stack, TextField, Autocomplete } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUsers, getUser } from '../../redux/user'
import { postBuyOrder } from '../../redux/foodOrder'
import { getPreciseDistance } from 'geolib';
import { HTTP_STATUS } from '../../utils/constants';
// import axios from 'axios'

export default function NewFoodOrder() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { users, user } = useSelector(state => state.user)
    const { loading } = useSelector(state => state.foodOrder)

    // const [userCoords, setUserCoords] = useState({})
    // const [placeCoords, setPlaceCoords] = useState({})
    const [userId, setUserId] = useState(null)
    const [userAddressId, setUserAddressId] = useState(null)

    useEffect(() => {
        dispatch(getUsers())
    }, [])

    const data = users.map(item => {
        let newData = {
            label: item.email,
            id: item.origId
        }
        return newData
    })

    let userAddressData = null

    if (user) {
        userAddressData = user?.userAddress?.map(item => {
            let newData = {
                label: `(${item.addressName}) ${item.address}`,
                id: item._id
            }
            return newData
        })
    }
    

    // MAPS AUTOCOMPLETE SECTION
    // const [value, setValue] = React.useState(null);
    // const [inputValue, setInputValue] = React.useState('');
    const [userAddress, setUserAddress] = useState(null);
    const [inputUserAddress, setInputUserAddress] = useState('');
    const [placeAddress, setPlaceAddress] = useState(null);
    const [inputPlaceAddress, setInputPlaceAddress] = useState('');
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        try {

            // const buyOrder = {
            //     request: data.get('request'),
            //     offer: data.get('offer'),
            //     userAddress: userAddressId,
            //     // userAddress: userAddress.description,
            //     placeAddress: placeAddress.description,
            //     placeName: placeAddress.structured_formatting.main_text,
            //     distanceToUser: distance.toString(),
            //     placeCoords,
            //     // userCoords,
            //     user: userId,
            // }

            // await dispatch(postBuyOrder(buyOrder))

        } catch (error) {
            console.log(error)
        }

    }

    const handleSetUserId = id => {
        setUserId(id)
        dispatch(getUser(id))
    }


    return (
        <Stack flex={4} paddingX={2}>
            <Typography variant='h5' fontWeight='bold'>New Order</Typography>
            <Box component='form' onSubmit={handleSubmit} sx={{ flexGrow: 1 }} marginTop={2}>
                <Grid container spacing={1}>
                    <Grid container item spacing={3}>
                        <Grid item xs={6}>
                            {/* <TextField id="request" label="Order Request" variant='outlined' required fullWidth /> */}
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="request"
                                label="Order Request"
                                type="text"
                                id="request"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="offer" label="Offer" name="offer" type="text" variant='outlined' fullWidth required margin="normal" />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={6}>
                            <Autocomplete
                                disablePortal
                                id="user"
                                options={data}
                                // sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="User" required margin='normal' />}
                                onChange={(event, value) => handleSetUserId(value.id)}
                            />

                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                disablePortal
                                id="userAddress"
                                options={userAddressData}
                                disabled={user ? false : true}
                                // sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="User Address" required margin='normal' />}
                                onChange={(event, value) => setUserAddressId(value.id)}
                            />
                            {/* <TextField id="placeAddress" label="Place Address" type="password" variant='outlined' fullWidth /> */}
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={6}>
                           

                        </Grid>
                        {/* <Grid item xs={6}>
                            <TextField id="address" label="Address" variant='outlined' fullWidth />
                        </Grid> */}
                    </Grid>
                </Grid>
                <Button disabled={loading === HTTP_STATUS.PENDING ? true : false} type='submit' variant='contained' sx={{ marginTop: 3, width: 200 }}>
                    {
                        loading === HTTP_STATUS.PENDING &&
                        <div class="animate-spin h-5 w-5 mr-3 border-b-2 rounded-full border-blue-900" />
                    }
                    Create
                </Button>
            </Box>
        </Stack>
    );
}

