import React, { useState } from 'react';
import { images } from '../../assets'
import { useNavigate } from 'react-router-dom'
import { Typography, Stack, Button, Card, Grid, Avatar, TextField, Box, IconButton } from '@mui/material';
import { PhotoCamera, PhoneAndroid, EmailOutlined, CalendarToday, LocationOn } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { updateAdmin, updateProfilePhoto } from '../../redux/admin';
import { ref, getDownloadURL, uploadBytes } from "firebase/storage"
import storage from "../../firebase"

export default function Admin() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { adminProfile } = useSelector(state => state.admin)
    const [photo, setPhoto] = useState(adminProfile.profilePhoto)


    const Input = styled('input')({
        display: 'none',
    });

    const handleSubmit = async event => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        // for (const [key, value] of data.entries()) {
        //     console.log(`${key}: ${value}`)
        //   }

        // console.log(photo)

        // new Response(data).text().then(console.log)


        try {

            if (typeof photo === 'object') {
                const storageRef = ref(storage, `admins/${adminProfile.id}/profilePhoto.jpg`)
                uploadBytes(storageRef, photo)
                    .then((snapshot) => {
                        // e.target[0].value = ''
                        getDownloadURL(snapshot.ref).then((downloadURL) => {
                            // DISPATCH TO UPDATE PROFILE PHOTO ONLY
                            const userdata = new FormData()
                            userdata.append('profilePhoto', downloadURL)
                            dispatch(updateProfilePhoto(userdata))
                        })
                    })
            }

            data.append('id', adminProfile.id)

            dispatch(updateAdmin(data))

        } catch (error) {
            console.log(error)
        }

    }

    return (
        <Stack className="user" paddingX={2}>
            {/* <Stack direction='row' alignItems='center' justifyContent='space-between' >
                <Typography variant='h5' fontWeight='bold'>Edit My Account</Typography>f
                <Button variant='contained' onClick={() => navigate('/new-user')}>
                    Create
                </Button>
            </Stack> */}

            <Grid container spacing={2} marginTop={1}>
                <Grid item xs={12} md={4}>
                    <Card sx={{ padding: 2, minHeight: 288 }}>
                        <Stack direction='row' alignItems='center' spacing={2}>
                            <Avatar alt="User" src={adminProfile.profilePhoto} />
                            <Stack>
                                <Typography fontWeight='bold' variant='body2'>{`${adminProfile.firstName} ${adminProfile.lastName}`}</Typography>
                                <Typography variant='subtitle2' color="text.secondary">{adminProfile.role}</Typography>
                            </Stack>
                        </Stack>
                        <Stack sx={{ marginTop: 3 }} spacing={2}>
                            <Typography variant='subtitle2' color="#A4A4A4" fontWeight='bold'>Contact Details</Typography>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <PhoneAndroid htmlColor="#A4A4A4" />
                                <Typography variant='subtitle2' color="text.secondary" >{adminProfile.mobilePhone}</Typography>
                            </Stack>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <EmailOutlined htmlColor="#A4A4A4" />
                                <Typography variant='subtitle2' color="text.secondary" >{adminProfile.email}</Typography>
                            </Stack>
                            {/* <Stack direction='row' alignItems='center' spacing={1}>
                                <CalendarToday htmlColor="#A4A4A4"/>
                                <Typography variant='subtitle2' color="text.secondary" >10 February 2022</Typography>
                            </Stack> */}
                            {/* <Stack direction='row' alignItems='center' spacing={1}>
                                <LocationOn htmlColor="#A4A4A4" />
                                <Typography variant='subtitle2' color="text.secondary" >Ampang, Kuala Lumpur</Typography>
                            </Stack> */}
                        </Stack>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card sx={{ padding: 2 }}>
                        <Typography variant='h6' fontWeight='bold'>Edit</Typography>
                        <Box component='form' onSubmit={handleSubmit}>
                            <Stack direction='row' justifyContent='space-between'>

                                <Stack marginY={1} spacing={2}>
                                    {/* <Box
                                        component="form"
                                        sx={{
                                            '& > :not(style)': { width: '40ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    > */}
                                    <TextField id="first-name" sx={{ '& > :not(style)': { width: '40ch' }, }} name='firstName' key={adminProfile.firstName} label="First Name" variant="standard" defaultValue={adminProfile.firstName} />
                                    {/* </Box> */}
                                    <TextField id="last-name" name='lastName' key={adminProfile.lastName} label="Last Name" variant="standard" defaultValue={adminProfile.lastName} />
                                    <TextField id="email" name='email' key={adminProfile.email} label="Email" variant="standard" defaultValue={adminProfile.email} />
                                    <TextField id="mobile-phone" name='mobilePhone' key={adminProfile.mobilePhone} label="Mobile No." variant="standard" defaultValue={adminProfile.mobilePhone} />
                                    {/* <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { width: '40ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField id="address" label="Address" variant="standard" />
                                </Box> */}
                                </Stack>

                                <Stack justifyContent='space-between'>
                                    <Stack direction='row' alignItems='center' >
                                        <Avatar
                                            alt="admin"
                                            src={photo && typeof photo !== 'string' ? URL.createObjectURL(photo) : adminProfile.profilePhoto}
                                            // src={images.businessman}
                                            sx={{ width: 120, height: 120 }}
                                        />
                                        <label htmlFor="icon-button-file">
                                            <Input onChange={value => setPhoto(value.currentTarget.files[0])} accept="image/*" id="icon-button-file" type="file" />
                                            <IconButton color="primary" aria-label="upload picture" component="span">
                                                <PhotoCamera />
                                            </IconButton>
                                        </label>
                                    </Stack>
                                    <Button type='submit' variant='contained'>Update</Button>
                                </Stack>

                            </Stack>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Stack>
    );
}
