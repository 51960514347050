import React, { useEffect, useState } from 'react';
import { images } from '../../assets'
import { useNavigate } from 'react-router-dom'
import { Typography, Stack, Button, Card, CardContent, Grid, Avatar, TextField, Box, IconButton, Autocomplete, ImageList, ImageListItem, ImageListItemBar, Dialog } from '@mui/material';
import { PhotoCamera, PhoneAndroid, EmailOutlined, CalendarToday, LocationOn, Pageview, Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Slide, AppBar, Toolbar } from "@material-ui/core";
import { saveAs } from 'file-saver'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedAgent, updateAgent } from '../../redux/agent'
import { HTTP_STATUS } from '../../utils/constants'
import Loader from '../../components/Loader'
import {format} from 'date-fns'
// import {} from '../../redux/user'

function srcset(image, width, height, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${width * cols}&h=${height * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Agent() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { selectedAgentId, agent, updateAgentStatus, loading } = useSelector(state => state.agent)
    const [photo, setPhoto] = useState(agent.profilePhoto)
    const [selectedTile, setSelectedTile] = useState(null);

    const agentTypes = [
        { label: 'rider', id: 1 },
        { label: 'provider', id: 2 },
    ]

    const agentStatus = [
        { label: 'active', id: 1 },
        { label: 'inactive', id: 2 },
    ]

    useEffect(() => {
        console.log('id', selectedAgentId)
        dispatch(getSelectedAgent(selectedAgentId))
    }, [])

    // console.log(user)

    const Input = styled('input')({
        display: 'none',
    });

    const handleSubmit = async event => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        data.append('id', selectedAgentId)
        const finalPhoto = typeof photo === 'object' ? photo : agent.profilePhoto
        data.append('profilePhotoURL', finalPhoto)
        // console.log(data.get('address'))

        try {
            // console.log(data.get('agentType'))
            dispatch(updateAgent(data))
            // const userData = {
            //     firstName: data.get('firstName'),
            //     lastName: data.get('lastName'),
            //     email: data.get('email'),
            //     mobilePhone: data.get('mobilePhone'),
            //     address: data.get('address'),
            // }

            // console.log(photo)
            // console.log('profilePhoto', data.get('profilePhoto'))
        } catch (error) {
            console.log(error)
        }

    }

    // useEffect(() => {
    //     if (updateAgentStatus === 'success') {
    //         navigate('/agents')
    //     }
    //     console.log('status ', updateAgentStatus)
    // }, [updateAgentStatus])

    const itemData = [
        {
            img: agent?.VehiclePhoto,
            title: 'Vehicle Photo',
            featured: true,
        },
        {
            img: agent?.RoadTaxPhoto,
            title: 'Road Tax',
        },
        {
            img: agent?.DriverLicensePhoto,
            title: 'Driver License',
        },
    ];

    const handleClickOpen = tile => {
        setSelectedTile(tile);
        console.log("clicked");
        console.log(tile);
    };

    const handleClose = () => {
        setSelectedTile(null);
    };

    return (
        <Stack flex={4} paddingX={2}>
            <Stack direction='row' alignItems='center' justifyContent='space-between' >
                <Typography variant='h5' fontWeight='bold'>Edit Agent</Typography>
                <Button variant='contained' onClick={() => navigate('/new-agent')}>
                    Create
                </Button>
            </Stack>

            <Grid container spacing={2} marginTop={1}>
                <Grid item xs={12} md={4}>
                    <Stack spacing={2}>
                        <Card sx={{ padding: 2, }}>
                            {
                                loading === HTTP_STATUS.PENDING ? (
                                    // true ? (
                                    <div class="animate-pulse flex space-x-4 py-7">

                                        <div class="flex-1 space-y-6">
                                            <div className='flex flex-row items-center space-x-3'>
                                                <div class="rounded-full bg-gray-300 h-10 w-10"></div>
                                                <div class="h-2 bg-gray-300 rounded basis-1/4"></div>
                                            </div>
                                            <div class="h-2 bg-gray-300 rounded"></div>
                                            <div class="h-2 bg-gray-300 rounded"></div>
                                            <div class="h-2 bg-gray-300 rounded"></div>
                                            <div class="h-2 bg-gray-300 rounded"></div>
                                            {/* <div class="h-2 bg-gray-300 rounded"></div> */}
                                            {/* <div class="space-y-3">
                                                        <div class="grid grid-cols-3 gap-4">
                                                            <div class="h-2 bg-gray-300 rounded col-span-2"></div>
                                                            <div class="h-2 bg-gray-300 rounded col-span-1"></div>
                                                        </div>
                                                        <div class="h-2 bg-gray-300 rounded"></div>
                                                    </div> */}
                                        </div>
                                    </div>
                                ) : (
                                    <CardContent style={{ flexGrow: 1 }}>
                                        <Stack direction='row' alignItems='center' spacing={2}>
                                            <Avatar alt="Agent" src={agent.profilePhoto} />
                                            <Stack>
                                                <Typography fontWeight='bold' variant='body2'>{agent?.firstName} {agent?.lastName}</Typography>
                                                <Typography variant='subtitle2' color="text.secondary">Papperoger Agent</Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack sx={{ marginTop: 3 }} spacing={2}>
                                            <Typography variant='subtitle2' color="#A4A4A4" fontWeight='bold'>Contact Details</Typography>
                                            <Stack direction='row' alignItems='center' spacing={1}>
                                                <PhoneAndroid htmlColor="#A4A4A4" />
                                                <Typography variant='subtitle2' color="text.secondary" >{agent?.mobilePhone}</Typography>
                                            </Stack>
                                            <Stack direction='row' alignItems='center' spacing={1}>
                                                <EmailOutlined htmlColor="#A4A4A4" />
                                                <Typography variant='subtitle2' color="text.secondary" >{agent?.email}</Typography>
                                            </Stack>
                                            <Stack direction='row' alignItems='center' spacing={1}>
                                                <LocationOn htmlColor="#A4A4A4" />
                                                <Typography variant='subtitle2' color="text.secondary" >{agent?.agentType ? agent.agentType : 'No Type'}</Typography>
                                            </Stack>
                                            <Stack direction='row' alignItems='center' spacing={1}>
                                                <CalendarToday htmlColor="#A4A4A4" />
                                                <Typography variant='subtitle2' color="text.secondary" >{agent?.createdAt && format(new Date(agent?.createdAt), 'dd MMMM yyy, p')}</Typography>
                                            </Stack>
                                        </Stack>
                                    </CardContent>
                                )
                            }

                        </Card>
                        <Card>
                            {

                                loading === HTTP_STATUS.PENDING ? (
                                    // true ? (
                                    <Loader />
                                    // <div class="animate-pulse flex space-x-4 py-7">


                                    // </div>
                                ) : (
                                    <CardContent style={{ flexGrow: 1 }}>
                                        <ImageList
                                            sx={{
                                                width: '100%',
                                                height: 450,
                                                // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
                                                transform: 'translateZ(0)',
                                            }}
                                            rowHeight={200}
                                            gap={1}
                                        >
                                            {itemData.map((item) => {
                                                const cols = item.featured ? 2 : 1;
                                                const rows = item.featured ? 2 : 1;

                                                return (
                                                    <ImageListItem key={item.img} cols={cols} rows={rows}>
                                                        <img
                                                            {...srcset(item.img, 250, 200, rows, cols)}
                                                            alt={item.title}
                                                            loading="lazy"
                                                        />
                                                        <ImageListItemBar
                                                            sx={{
                                                                background:
                                                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                            }}
                                                            title={item.title}
                                                            position="top"
                                                            actionIcon={
                                                                <IconButton
                                                                    onClick={() => handleClickOpen(item)}
                                                                    sx={{ color: 'white' }}
                                                                    aria-label={`star ${item.title}`}
                                                                >
                                                                    <Pageview />
                                                                </IconButton>
                                                            }
                                                            actionPosition="left"
                                                        />
                                                    </ImageListItem>
                                                );
                                            })}
                                        </ImageList>
                                    </CardContent>
                                )
                            }

                        </Card>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card sx={{ padding: 2 }}>
                        {
                            loading === HTTP_STATUS.PENDING ? (
                                // true ? (
                                <div class="animate-pulse flex space-x-4 py-7">

                                    <div class="flex-1 space-y-10">
                                        <div className='flex flex-row items-center space-x-5'>
                                            <div class="h-4 bg-gray-300 rounded basis-3/4"></div>
                                            <div class="rounded-full bg-gray-300 h-32 w-32"></div>
                                        </div>
                                        <div class="h-4 bg-gray-300 rounded"></div>
                                        <div class="h-4 bg-gray-300 rounded"></div>
                                        <div class="h-4 bg-gray-300 rounded"></div>
                                        <div class="h-4 bg-gray-300 rounded"></div>
                                        {/* <div class="h-2 bg-gray-300 rounded"></div> */}
                                        {/* <div class="space-y-3">
                                                        <div class="grid grid-cols-3 gap-4">
                                                            <div class="h-2 bg-gray-300 rounded col-span-2"></div>
                                                            <div class="h-2 bg-gray-300 rounded col-span-1"></div>
                                                        </div>
                                                        <div class="h-2 bg-gray-300 rounded"></div>
                                                    </div> */}
                                    </div>
                                </div>
                            ) : (
                                <CardContent style={{ flexGrow: 1 }}>
                                    <Typography variant='h6' fontWeight='bold'>Edit</Typography>
                                    <Box component='form' onSubmit={handleSubmit}>
                                        <Stack direction='row' justifyContent='space-between'>

                                            <Stack marginY={1}>

                                                <TextField id="first-name" sx={{ '& > :not(style)': { width: '40ch' }, }} name='firstName' defaultValue={agent?.firstName} key={agent.firstName} margin='normal' label="First Name" variant="standard" />

                                                <TextField id="last-name" sx={{ '& > :not(style)': { width: '40ch' }, }} name='lastName' defaultValue={agent?.lastName} key={agent.lastName} margin='normal' label="Last Name" variant="standard" />

                                                <TextField id="email" sx={{ '& > :not(style)': { width: '40ch' }, }} name='email' defaultValue={agent?.email} margin='normal' key={agent.email} label="Email" variant="standard" />

                                                <TextField id="mobile" sx={{ '& > :not(style)': { width: '40ch' }, }} name='mobilePhone' defaultValue={agent?.mobilePhone} key={agent.mobilePhone} margin='normal' label="Mobile No." variant="standard" />

                                                <Autocomplete
                                                    disablePortal
                                                    id="agentType"
                                                    options={agentTypes}
                                                    sx={{ width: 300 }}
                                                    defaultValue={agent?.agentType}
                                                    key={agent?.agentType}
                                                    renderInput={(params) => <TextField {...params} label="Agent Type" name='agentType' key={agent.agentType} required margin='normal' />}
                                                // onChange={(event, value) => setPayment(value.label)}
                                                />

                                                <Autocomplete
                                                    disablePortal
                                                    id="status"
                                                    options={agentStatus}
                                                    sx={{ width: 300 }}
                                                    defaultValue={agent?.status}
                                                    key={agent?.status}
                                                    renderInput={(params) => <TextField {...params} label="Order Status" name='status' key={agent.status} required margin='normal' />}
                                                // onChange={(event, value) => setPayment(value.label)}
                                                />
                                                {/* <TextField id="address" sx={{'& > :not(style)': { width: '40ch' },}} name='address' defaultValue={user?.address} key={user.address} label="Address" margin='normal' variant="standard" /> */}

                                            </Stack>

                                            <Stack justifyContent='space-between'>
                                                <Stack direction='row' alignItems='center' >
                                                    <Avatar
                                                        alt="user"
                                                        src={photo && typeof photo !== 'string' ? URL.createObjectURL(photo) : agent.profilePhoto}
                                                        // src={photo && typeof photo !== 'string' ? URL.createObjectURL(photo) : photo}
                                                        // src={photo}
                                                        sx={{ width: 120, height: 120 }}
                                                    />
                                                    <label htmlFor="icon-button-file">
                                                        <Input onChange={value => setPhoto(value.currentTarget.files[0])} name='profilePhoto' accept="image/*" id="icon-button-file" type="file" />
                                                        {/* <Input onChange={value => console.log(URL.createObjectURL(value.currentTarget.files[0]))} name='profilePhoto' accept="image/*" id="icon-button-file" type="file" /> */}
                                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                                            <PhotoCamera />
                                                        </IconButton>
                                                    </label>
                                                </Stack>
                                                <Button type='submit' variant='contained'>Update</Button>
                                            </Stack>

                                        </Stack>
                                    </Box>
                                </CardContent>
                            )
                        }

                    </Card>
                </Grid>
            </Grid>

            <Dialog
                fullScreen
                open={selectedTile !== null}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar>
                    <Toolbar style={{ justifyContent: 'space-between' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Close />
                        </IconButton>
                        <Typography variant="h6">
                            {selectedTile?.title}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={() => saveAs(selectedTile?.img, `${selectedTile?.title.split(" ").join('_')}.jpg`)}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>

                {selectedTile && (
                    <img src={selectedTile.img} alt={selectedTile.title} />
                )}
                {/* <List>
          <ListItem button>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Default notification ringtone"
              secondary="Tethys"
            />
          </ListItem>
        </List> */}
            </Dialog>
        </Stack>
    );
}
