import React, { useState, useEffect } from 'react';
import { images } from '../../assets'
import { DataGrid } from '@mui/x-data-grid';
import { message } from 'antd'
import { Stack, Avatar, IconButton, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Chip } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getMerchants, setSelectedMerchantId, deleteMerchant } from '../../redux/merchant.js'
import { useNavigate } from 'react-router-dom'
import { HTTP_STATUS } from '../../utils/constants'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MerchantList() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { merchants, loading } = useSelector(state => state.merchant)
  const [messageApi, contextHolder] = message.useMessage();

  const [merchantId, setMerchantId] = useState('');

  // Dialog Section
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const successMessage = (msg) => {
    messageApi.open({
      type: 'success',
      content: msg,
    });
  };

  const errorMessage = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };

  // UseEffect Section
  useEffect(() => {
    dispatch(getMerchants())
  }, [])

  // const [data, setData] = useState(agents)

  // console.log('data', data)

  // const [data, setData] = useState([
  //   { id: 1, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 2, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 3, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 4, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 5, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 6, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 7, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 8, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 9, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 10, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 11, user: 'Jon Snow', email: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  // ])

  // useEffect(() => {
  //   console.log(deleteAgentStatus)
  //   if (deleteAgentStatus === 'success') {
  //     console.log('success')
  //     dispatch(getMerchants())
  //   }
  // }, [deleteAgentStatus])

  const handleDialog = id => {
    console.log(id)
    setMerchantId(id)
    handleClickOpen()
  }

  const handleDelete = () => {

    // const formData = new FormData()
    // formData.append('id', agentId)

    const data = {
      merchantId,
      successMessage,
      errorMessage
    }

    dispatch(deleteMerchant(data))
    handleClose()
    // setData(users.filter(item => item.origId !== origId))
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'merchant', headerName: 'Merchant', width: 200, renderCell: (params) => (
        <Stack direction='row' alignItems='center' spacing={1}>
          <Avatar alt="merchant" src={params.row.logoUrl} />
          <span>
            {params.row.merchant}
          </span>
        </Stack>
      )
    },
    // { field: 'lastName', headerName: 'Last name', width: 100 },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 140,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
    {
      field: 'email',
      headerName: 'Email',
      // type: 'number',
      width: 200,
    },
    {
      field: 'mobile',
      headerName: 'Mobile No.',
      // type: 'number',
      width: 120,
    },
    {
      field: 'merchantCategory',
      headerName: 'Category',
      // type: 'number',
      width: 100,
    },
    {
      field: 'foodCategoryCount',
      headerName: 'Categories',
      // type: 'number',
      width: 90,
    },
    {
      field: 'foodItemCount',
      headerName: 'Items',
      // type: 'number',
      width: 60,
    },
    {
      field: 'storeOpen',
      headerName: 'Store Open',
      // type: 'number',
      width: 110,
      renderCell: params => (
        <Chip label={params.row.storeOpen ? 'Yes' : 'No'} color={params.row.storeOpen ? 'success' : 'error'} />
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      // type: 'number',
      width: 110,
      renderCell: params => (
        <Chip label={params.row.active ? 'Active' : 'Inactive'} color={params.row.active ? 'success' : 'error'} />
      )
    },
    {
      field: 'action', headerName: 'Action', width: 150, renderCell: params => (
        <Stack direction="row" spacing="2" alignItems='center'>
          <Link to={"/merchant/" + params.row.id}>
            <IconButton onClick={() => dispatch(setSelectedMerchantId(params.row.origId))} color="success" aria-label="edit merchant" component="span">
              <Edit />
            </IconButton>
          </Link>
          <IconButton onClick={() => handleDialog(params.row.origId)} color="error" aria-label="delete merchant" component="span">
            <Delete />
          </IconButton>
        </Stack>

      )
    },
  ];

  return (
    <div className='basis-full p-4'>
      {contextHolder}
      <Stack direction='row' alignItems='center' justifyContent='space-between' paddingY={2}>
        <Typography variant='h5' fontWeight='bold'>Merchant List</Typography>
        <Button variant='contained' onClick={() => navigate('/new-agent')}>
          Create
        </Button>
      </Stack>
      {
        loading === HTTP_STATUS.PENDING ? (
          // true ? (
          <div class="animate-pulse flex p-7">

            <div class="flex-1 space-y-10">
              <div class="h-5 bg-gray-300 rounded w-1/2"></div>
              <div class="h-5 bg-gray-300 rounded"></div>
              <div class="h-5 bg-gray-300 rounded"></div>
              <div class="h-5 bg-gray-300 rounded"></div>
              <div class="h-5 bg-gray-300 rounded"></div>
              {/* <div class="h-2 bg-gray-300 rounded"></div> */}
              {/* <div class="space-y-3">
                                  <div class="grid grid-cols-3 gap-4">
                                      <div class="h-2 bg-gray-300 rounded col-span-2"></div>
                                      <div class="h-2 bg-gray-300 rounded col-span-1"></div>
                                  </div>
                                  <div class="h-2 bg-gray-300 rounded"></div>
                              </div> */}
            </div>
          </div>
        ) : (
          <DataGrid
            rows={merchants}
            columns={columns}
            pageSize={8}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            autoPageSize
            autoHeight={true}
          />
        )
      }


      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirm delete agent?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this merchant?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDelete}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
