import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { Typography, Grid, Box, Button, Stack, TextField, Backdrop, CircularProgress, Snackbar, RadioGroup, Radio, FormControl, FormControlLabel, FormLabel, Autocomplete } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createUser, getUsers } from '../../redux/user'
import { getAgents } from '../../redux/agent'
import { getMerchants } from '../../redux/merchant'
import { sendMessage } from '../../redux/message'
import { Loader } from '../../components'
import { HTTP_STATUS } from '../../utils/constants';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Notification() {
    // const agentsRef = useRef()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { users } = useSelector(state => state.user)
    const { agents } = useSelector(state => state.agent)
    const { merchants } = useSelector(state => state.merchant)
    const { loading } = useSelector(state => state.message)

    const [messageReceiver, setMessageReceiver] = useState()
    const [agentId, setAgentId] = useState()
    const [agentValue, setAgentValue] = useState()
    const [userId, setUserId] = useState()
    const [merchantId, setMerchantId] = useState()
    const [message, setMessage] = useState()

    useLayoutEffect(() => {
        dispatch(getUsers())
        dispatch(getAgents())
        dispatch(getMerchants())
        console.log('merchant', merchants)
    }, [])

    const userData = users.map(item => {
        let newData = {
            label: item.email,
            id: item.origId
        }
        return newData
    })

    const agentData = agents.map(item => {
        let newData = {
            label: item.email,
            id: item.origId
        }
        return newData
    })

    const merchantData = merchants.map(item => {
        let newData = {
            label: item.email,
            id: item.origId
        }
        return newData
    })

    // const merchantData = [
    //     {
    //         label: merchant.email,
    //         id: merchant.origId
    //     }
    // ]


    //LOADING
    // const [open, setOpen] = React.useState(false);
    // const handleClose = () => {
    //     setOpen(false);
    // };
    // const handleToggle = () => {
    //     setOpen(!open);
    // };

    //SNACKBAR
    // const [snackOpen, setSnackOpen] = React.useState(false);

    // const handleSnackClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }

    //     setSnackOpen(false);
    // };

    //HANDLE SUBMIT
    const handleSubmit = async event => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        console.log(data.get('title'), data.get('subtitle'), message)
        // console.log(data.get('firstName'))

        if (!message) {
            return alert('Please enter the message')
        }

        // SEND TO SPECIFIC USER
        if (messageReceiver === 'user') {
            if (userId) {
                console.log('User selected')
                const userMessage = {
                    title: data.get('title'),
                    subtitle: data.get('subtitle'),
                    notificationText: data.get('notificationText'),
                    message,
                    user: userId,
                    messageReceiver,
                }
                dispatch(sendMessage(userMessage))
                alert('Message sent!')
                // console.log(data.get('title'), data.get('subtitle'), message, messageReceiver)
            } else {
                return alert('Please select the user', userId)
            }

            // SEND TO SPECIFIC AGENT
        } else if (messageReceiver === 'agent') {
            if (agentId) {
                console.log('Agent selected')
                const agentMessage = {
                    title: data.get('title'),
                    subtitle: data.get('subtitle'),
                    notificationText: data.get('notificationText'),
                    message,
                    agent: agentId,
                    messageReceiver,
                }
                dispatch(sendMessage(agentMessage))
                alert('Message sent!')
            } else {
                return alert('Please select the agent')
            }

        } else if (messageReceiver === 'merchant') {
            if (merchantId) {
                console.log('Merchant selected')
                const merchantMessage = {
                    title: data.get('title'),
                    subtitle: data.get('subtitle'),
                    notificationText: data.get('notificationText'),
                    message,
                    merchant: merchantId,
                    messageReceiver,
                }
                dispatch(sendMessage(merchantMessage))
                alert('Message sent!')
            } else {
                return alert('Please select the agent')
            }
        }

        // SEND TO TOPIC
        else if (messageReceiver === 'group') {
            if (data.get('topic')) {
                console.log('Topic selected')
                const topicMessage = {
                    title: data.get('title'),
                    subtitle: data.get('subtitle'),
                    notificationText: data.get('notificationText'),
                    message,
                    topicName: data.get('topic'),
                    messageReceiver,
                }
                dispatch(sendMessage(topicMessage))
                alert('Message sent!')
            } else {
                return alert('Please enter the topic name')
            }
        } else {
            return alert('Please select the message receiver')
        }

        try {
            // await dispatch(createUser(data))
        } catch (error) {
            console.log(error)
        }
    }

    // useEffect(() => {
    //     if (createUserStatus === 'success') {
    //         navigate('/users')
    //     }
    // }, [createUserStatus])

    const handleSendTo = (event) => {
        // agentsRef.current.clearIndicator()
        // setAgentValue('')
        setAgentId([])
        setUserId([])
        console.log(event.target.value)
        setMessageReceiver(event.target.value);
    };

    return (
        <Stack flex={4} paddingX={2}>
            {/* <Loader /> */}
            <Typography variant='h5' fontWeight='bold'>Send Notification</Typography>
            <Box component='form' onSubmit={handleSubmit} sx={{ flexGrow: 1 }} marginTop={2}>
                <Grid container spacing={1}>
                    <Grid container item spacing={3}>
                        <Grid item xs={6}>
                            <TextField id="title" name='title' label="Title" variant='outlined' fullWidth required />
                        </Grid>
                        <Grid item xs={6} />
                        <Grid item xs={6} >
                            <TextField id="subtitle" name='subtitle' label="Subtitle" variant='outlined' fullWidth required />
                        </Grid>
                        <Grid item xs={6} />
                        <Grid item xs={6} >
                            <TextField id="notificationText" name='notificationText' label="Notification Text" variant='outlined' fullWidth required />
                        </Grid>
                        <Grid item xs={12}>
                            <CKEditor
                                editor={ClassicEditor}
                                data="<p>Write the message here ...</p>"
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    console.log({ event, editor, data });
                                    setMessage(data)
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Send to</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    // defaultValue="user"
                                    name="radio-buttons-group"
                                    onChange={handleSendTo}
                                >
                                    <FormControlLabel value="user" control={<Radio />} label="User" />
                                    <FormControlLabel value="merchant" control={<Radio />} label="Merchant" />
                                    <FormControlLabel value="agent" control={<Radio />} label="Agent" />
                                    <FormControlLabel value="group" control={<Radio />} label="Group" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                messageReceiver === 'group' ?
                                    (
                                        <TextField id="topic" name='topic' label="Topic" variant='outlined' fullWidth required />
                                    ) : messageReceiver === 'user' ? (
                                        <Autocomplete
                                            disablePortal
                                            id="user"
                                            options={userData}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} label="User" required margin='normal' />}
                                            onChange={(event, value) => setUserId(value.id)}
                                        />
                                    ) : messageReceiver === 'agent' ? (
                                        <Autocomplete
                                            // ref={agentsRef}
                                            disablePortal
                                            id="agent"
                                            options={agentData}
                                            sx={{ width: 300 }}
                                            // value={agentId}
                                            // inputValue={agentValue}
                                            // onInputChange={(event, newInputValue) => {
                                            //     setAgentValue(newInputValue);
                                            // }}
                                            renderInput={(params) => <TextField {...params} label="Agent" required margin='normal' />}
                                            onChange={(event, value) => setAgentId(value.id)}
                                        />
                                    ) : messageReceiver === 'merchant' ? (
                                        <Autocomplete
                                            // ref={agentsRef}
                                            disablePortal
                                            id="merchant"
                                            options={merchantData}
                                            sx={{ width: 300 }}
                                            // value={agentId}
                                            // inputValue={agentValue}
                                            // onInputChange={(event, newInputValue) => {
                                            //     setAgentValue(newInputValue);
                                            // }}
                                            renderInput={(params) => <TextField {...params} label="Merchant" required margin='normal' />}
                                            onChange={(event, value) => setMerchantId(value.id)}
                                        />
                                    ) : null
                            }
                        </Grid>
                        {/* <Grid item xs={6}>
                            <TextField id="firstName" name='firstName' label="First Name" variant='outlined' fullWidth required />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="lastName" name='lastName' label="Last Name" variant='outlined' fullWidth required />
                        </Grid> */}
                    </Grid>
                    {/* <Grid container item spacing={3}>
                        <Grid item xs={6}>
                            <TextField id="email" name='email' label="Email" variant='outlined' fullWidth required />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="password" name='password' label="Password" type="password" variant='outlined' fullWidth required />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={3}>
                        <Grid item xs={6}>
                            <TextField id="mobile" name='mobilePhone' label="Mobile No." variant='outlined' fullWidth required />
                        </Grid>
                    </Grid> */}
                </Grid>
                <Button disabled={loading === HTTP_STATUS.PENDING ? true : false} type='submit' variant='contained' sx={{ marginTop: 3, width: 200 }}>
                    {
                        loading === HTTP_STATUS.PENDING &&
                        <div class="animate-spin h-5 w-5 mr-3 border-b-2 rounded-full border-blue-900" />
                    }
                    Send
                </Button>

                {/* <h1 className="text-3xl md:text-6xl lg:text-9xl font-bold underline">
                    Hello world!
                </h1> */}

            </Box>


            {/* {
                createUserStatus === 'loading' &&
                (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                        onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )
            }

            {
                createUserStatus === 'success' &&
                (
                    <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
                        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                            User has been added successfully!
                        </Alert>
                    </Snackbar>
                )
            } */}
        </Stack>
    );
}
