import { useState, useEffect } from 'react';
import { Topbar, Sidebar } from "./components";
import './app.css'
import { Home, UserList, User, NewUser, Login, AgentList, FoodOrderList, Admin, NewFoodOrder, NewAgent, Agent, FoodOrder, Notification, UserFeedback, AgentFeedback, MerchantList, CustomerSupport, SupportNumber, Merchant, CouponList, Coupon } from "./pages";
import { useSelector, useDispatch } from 'react-redux'
import { setToken } from './redux/admin'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { HistoryRouter } from "./utils/HistoryRouter"
import { myHistory } from "./utils/history"
import axios from 'axios';

const { REACT_APP_API_URL } = process.env

function App() {
  const dispatch = useDispatch()
  const { token } = useSelector(state => state.admin)
  console.log('token: ', token)

  const getToken = () => {
    const token = sessionStorage.getItem('token');
    return token
  };

  useEffect(() => {
    dispatch(setToken(getToken()))
  }, [])
  // const [token, setToken] = useState(getToken());

  // const [token, setToken] = useState(sessionStorage.getItem('token'));

  // const { token, setToken } = UseToken();
  // const token = sessionStorage.getItem('token');
  // console.log('Helloooo')

  if (!token) {
    return <Login />
  }
  // console.log('tooooken: ', token)

  // if (!token) {
  //   return (
  //     <Router>
  //       <Routes>
  //        <Route path="/" element={<Login setToken={setToken} />} />
  //       </Routes>
  //     </Router>
  //   )
  // }



  return (
    <HistoryRouter history={myHistory}>
    {/* <Router> */}
      {/* <Routes>
          <Route path="/login" element={<Login />} />
      </Routes> */}
      <div className="min-h-screen">
        {/* <div class="container"> */}
        <Topbar />
        {/* <div className='grid grid-cols-2'> */}
        {/* className='basis-1/2' */}
        <div className='xl:flex'>
          <Sidebar />
        {/* <Chat /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/agents" element={<AgentList />} />
            <Route path="/merchants" element={<MerchantList />} />
            <Route path="/food-orders" element={<FoodOrderList />} />
            <Route path="/user/:userId" element={<User />} />
            <Route path="/agent/:agentId" element={<Agent />} />
            <Route path="/merchant/:merchantId" element={<Merchant />} />
            <Route path="/food-order/:foodOrderId" element={<FoodOrder />} />
            <Route path="/coupon/:couponId" element={<Coupon />} />
            <Route path="/new-user" element={<NewUser />} />
            <Route path="/new-agent" element={<NewAgent />} />
            <Route path="/my-account" element={<Admin />} />
            <Route path="/new-food-order" element={<NewFoodOrder />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/user-feedback" element={<UserFeedback />} />
            <Route path="/agent-feedback" element={<AgentFeedback />} />
            <Route path="/customer-support" element={<CustomerSupport />} />
            <Route path="/support-number" element={<SupportNumber />} />
            <Route path="/coupons" element={<CouponList />} />
            <Route path="/coupon" element={<Coupon />} />
          </Routes>
        </div>

      </div>
    {/* </Router> */}
    </HistoryRouter>
  );
}

export default App;


