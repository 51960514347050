import React, { useEffect, useState } from 'react';
import './featuredInfo.css';
import { colors, images } from '../../assets';
import { Card, CardActions, CardContent, Typography, Button, Grid, Box, Stack, List, ListItemText, ListItemAvatar, ListItem, Avatar } from '@mui/material';
import { BikeScooter, AttachMoney, Person, StarBorder, DeliveryDining } from '@mui/icons-material';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useSelector, useDispatch } from 'react-redux';
import { getFoodOrders } from '../../redux/foodOrder';
import { getAgentRatings, getUserRatings } from '../../redux/rating';
import { format, subMonths } from 'date-fns'
import { HTTP_STATUS } from '../../utils/constants'

export default function FeaturedInfo() {
    const dispatch = useDispatch()
    const { foodOrders, profitThisMonth, profitSubOne, profitSubTwo, profitSubThree, profitSubFour, profitSubFive, loading } = useSelector(state => state.foodOrder)
    const { agentRatings, userRatings, loading: ratingLoading } = useSelector(state => state.rating)
    const [earnings, setEarnings] = useState(0)
    const [topAgent, setTopAgent] = useState('')
    const [bestUser, setBestUser] = useState('')
    const [topAgentRatio, setTopAgentRatio] = useState(0)
    const [bestUserRatio, setBestUserRatio] = useState(0)
    const [bestAgentRating, setBestAgentRating] = useState(0)
    const [bestAgentRatingName, setBestAgentRatingName] = useState('None')
    const [bestUserRating, setBestUserRating] = useState(0)
    const [bestUserRatingName, setBestUserRatingName] = useState('None')

    const data = [
        {
            name: format(subMonths(new Date(), 5), 'MMM'),
            // Profit: 50,
            Profit: profitSubFive,
        },
        {
            name: format(subMonths(new Date(), 4), 'MMM'),
            // Profit: 123,
            Profit: profitSubFour,
        },
        {
            name: format(subMonths(new Date(), 3), 'MMM'),
            // Profit: 188,
            Profit: profitSubThree,
        },
        {
            name: format(subMonths(new Date(), 2), 'MMM'),
            // Profit: 211,
            Profit: profitSubTwo,
        },
        {
            name: format(subMonths(new Date(), 1), 'MMM'),
            // Profit: 244,
            Profit: profitSubOne,
        },
        {
            name: format(new Date(), 'MMM'),
            // Profit: 355,
            Profit: profitThisMonth,
        },
    ];

    useEffect(() => {
        dispatch(getFoodOrders())
        dispatch(getAgentRatings())
        dispatch(getUserRatings())
    }, [])

    useEffect(() => {
        // console.log(earnings)
        const totalEarnings = foodOrders.reduce((acc, curr) => {
            return acc + Number(curr.price)
        }, 0)
        setEarnings(totalEarnings)

        // Calculate the best Agent
        const agentBuyOrderIds = foodOrders.map(item => item.agentId)

        if (agentBuyOrderIds.length) {
            let agentOrders = {}
            agentBuyOrderIds.forEach(x => {
                if (x) {
                    agentOrders[x] = (agentOrders[x] || 0) + 1
                }
            })

            const topAgent = foodOrders.filter(item => item.agentId === Object.keys(agentOrders)[0])
            setTopAgent(topAgent[0].agent)

            //Calculate Agent percentage
            const totalOrdersByAgents = Object.values(agentOrders).reduce((acc, curr) => {
                return acc + curr
            }, 0)
            const agentRatio = (Object.values(agentOrders)[0] / totalOrdersByAgents) * 100
            setTopAgentRatio(agentRatio)
        }



        // Calculate the best User
        const userBuyOrderIds = foodOrders.map(item => item.userId)

        if (userBuyOrderIds.length) {
            let userOrders = {}
            userBuyOrderIds.forEach(x => {
                if (x) {
                    userOrders[x] = (userOrders[x] || 0) + 1
                }
            })
            const bestUser = foodOrders.filter(item => item.userId === Object.keys(userOrders)[0])
            setBestUser(bestUser[0].user)

            //Calculate Agent percentage
            const totalOrdersByUsers = Object.values(userOrders).reduce((acc, curr) => {
                return acc + curr
            }, 0)
            const userRatio = (Object.values(userOrders)[0] / totalOrdersByUsers) * 100
            setBestUserRatio(userRatio)
        }

    }, [foodOrders])

    useEffect(() => {

        // BEST AGENT RATING
        let totalAgents = {}
        let agentRating = {}
        agentRatings.forEach(x => {
            if (x.agent?._id) {
                totalAgents[x.agent?._id] = (totalAgents[x.agent?._id] || 0) + 1
                agentRating[x.agent?._id] = (agentRating[x.agent?._id] || 0) + x.rating
            }
        })

        if (Object.keys(totalAgents).length) {
            let agentsAvgRating = []

            Object.keys(totalAgents).forEach((item, idx) => {
                const avgRating = Object.values(agentRating)[idx] / Object.values(totalAgents)[idx]
                agentsAvgRating.push({ id: item, rating: avgRating, count: Object.values(totalAgents)[idx] })
                // console.log(avgRating)
            })

            const sortedAgentRating = agentsAvgRating.sort((a, b) => a.rating - b.rating).reverse()
            setBestAgentRating(sortedAgentRating[0].rating)

            const sortedAgentData = agentRatings.filter(item => item.agent?._id === sortedAgentRating[0].id)

            const sortedAgentName = sortedAgentData[0].agent?.firstName + ' ' + sortedAgentData[0].agent?.lastName
            setBestAgentRatingName(sortedAgentName)
        }

        // BEST USER RATING
        let totalUsers = {}
        let userRating = {}
        userRatings.forEach(x => {
            if (x.user?._id) {
                totalUsers[x.user?._id] = (totalUsers[x.user?._id] || 0) + 1
                userRating[x.user?._id] = (userRating[x.user?._id] || 0) + x.rating
            }
        })

        console.log(userRating)

        if (Object.keys(totalUsers).length) {
            let usersAvgRating = []

            Object.keys(totalUsers).forEach((item, idx) => {
                const avgRating = Object.values(userRating)[idx] / Object.values(totalUsers)[idx]
                usersAvgRating.push({ id: item, rating: avgRating, count: Object.values(totalUsers)[idx] })
                // console.log(avgRating)
            })

            const sortedUserRating = usersAvgRating.sort((a, b) => a.rating - b.rating).reverse()
            setBestUserRating(sortedUserRating[0]?.rating)

            const sortedUserData = userRatings.filter(item => item.user?._id === sortedUserRating[0]?.id)

            const sortedUserName = sortedUserData[0].user?.firstName + ' ' + sortedUserData[0].user?.lastName
            setBestUserRatingName(sortedUserName)
        }


        // console.log(userRatings)

    }, [])

    return (
        <div>
            {/* <Grid container sm={12} spacing={2}> */}
            {/* <Grid item xs={12} md={4}> */}
            <div className='grid gap-4 lg:grid-cols-3 p-4'>
                <div>
                    <Stack spacing={2}>
                        <Card sx={{ flex: 1, px: 2 }}>
                            {
                                loading === HTTP_STATUS.PENDING ? (
                                    <>
                                        <div class="animate-pulse flex space-x-4 py-7">

                                            <div class="flex-1 space-y-6">
                                                <div className='flex flex-row items-center space-x-3'>
                                                    <div class="h-2 bg-gray-300 rounded basis-1/4"></div>
                                                    <div class="rounded-full bg-gray-300 h-10 w-10"></div>
                                                </div>
                                                <div class="h-2 bg-gray-300 rounded"></div>
                                                <div class="h-2 bg-gray-300 rounded"></div>
                                                {/* <div class="h-2 bg-gray-300 rounded"></div> */}
                                                {/* <div class="space-y-3">
                                                    <div class="grid grid-cols-3 gap-4">
                                                        <div class="h-2 bg-gray-300 rounded col-span-2"></div>
                                                        <div class="h-2 bg-gray-300 rounded col-span-1"></div>
                                                    </div>
                                                    <div class="h-2 bg-gray-300 rounded"></div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <CardContent style={{ flexGrow: 1 }}>
                                        <Stack direction='row' alignItems='center' spacing={2}>
                                            <Typography sx={{ fontSize: 21 }} color="text.secondary" gutterBottom>
                                                Earnings
                                            </Typography>
                                            <div className='bg-gray-300 rounded-full w-7 h-7 items-center justify-center flex'>
                                                <AttachMoney style={{ alignSelf: 'center' }} />
                                            </div>
                                            {/* <Box sx={{ p: 1, backgroundColor: colors.borderGrey, width: 25, height: 25, borderRadius: 25, alignItems: 'center', justifyContent: 'center' }}>
                                        <AttachMoney style={{ alignSelf: 'center' }} />
                                    </Box> */}
                                        </Stack>
                                        {/* <Typography variant="h5" component="div" > */}
                                        <div className="featuredMoneyContainer">
                                            <span className="featuredMoney">RM{earnings.toFixed(2)}</span>
                                        </div>

                                        {/* </Typography> */}
                                        <Typography sx={{ fontSize: 15 }} color={colors.dimGray}>
                                            Total Revenue
                                        </Typography>
                                    </CardContent>
                                )
                            }

                        </Card>
                        <Card sx={{ flex: 1, px: 2 }}>
                            {
                                loading === HTTP_STATUS.PENDING ? (
                                    <>
                                        <div class="animate-pulse flex space-x-4 py-7">

                                            <div class="flex-1 space-y-6">
                                                <div className='flex flex-row items-center space-x-3'>
                                                    <div class="h-2 bg-gray-300 rounded basis-1/4"></div>
                                                    <div class="rounded-full bg-gray-300 h-10 w-10"></div>
                                                </div>
                                                <div class="h-2 bg-gray-300 rounded"></div>
                                                <div class="h-2 bg-gray-300 rounded"></div>
                                                {/* <div class="h-2 bg-gray-300 rounded"></div> */}
                                                {/* <div class="space-y-3">
                                                    <div class="grid grid-cols-3 gap-4">
                                                        <div class="h-2 bg-gray-300 rounded col-span-2"></div>
                                                        <div class="h-2 bg-gray-300 rounded col-span-1"></div>
                                                    </div>
                                                    <div class="h-2 bg-gray-300 rounded"></div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <CardContent style={{ flexGrow: 1 }}>
                                        <Stack direction='row' alignItems='center' spacing={2}>
                                            <Typography sx={{ fontSize: 21 }} color="text.secondary" gutterBottom>
                                                Orders
                                            </Typography>
                                            <div className='bg-gray-300 rounded-full w-7 h-7 items-center justify-center flex'>
                                                <DeliveryDining style={{ alignSelf: 'center', fontSize: 20 }} />
                                            </div>
                                            {/* <Box sx={{ p: 1, backgroundColor: colors.borderGrey, width: 25, height: 25, borderRadius: 25, alignItems: 'center', justifyContent: 'center' }}>
                                        <DeliveryDining style={{ alignSelf: 'center' }} />
                                    </Box> */}
                                        </Stack>
                                        {/* <Typography variant="h5" component="div" > */}
                                        <div className="featuredMoneyContainer">
                                            <span className="featuredMoney">{foodOrders?.length}</span>
                                        </div>

                                        {/* </Typography> */}
                                        <Typography sx={{ fontSize: 15 }} color={colors.dimGray}>
                                            Total Orders
                                        </Typography>
                                    </CardContent>
                                )
                            }

                        </Card>
                    </Stack>
                </div>

                {/* </Grid> */}
                {/* <Grid item xs={12} md={4}> */}
                <Card style={{ flex: 1, minHeight: 338, }}>
                    {
                        loading === HTTP_STATUS.PENDING && ratingLoading === HTTP_STATUS.PENDING ? (
                            // true ? (
                            <div class="animate-pulse flex p-7">

                                <div class="flex-1 space-y-10">
                                    <div class="h-5 bg-gray-300 rounded w-1/2"></div>
                                    <div class="h-5 bg-gray-300 rounded"></div>
                                    <div class="h-5 bg-gray-300 rounded"></div>
                                    <div class="h-5 bg-gray-300 rounded"></div>
                                    <div class="h-5 bg-gray-300 rounded"></div>
                                    {/* <div class="h-2 bg-gray-300 rounded"></div> */}
                                    {/* <div class="space-y-3">
                                                    <div class="grid grid-cols-3 gap-4">
                                                        <div class="h-2 bg-gray-300 rounded col-span-2"></div>
                                                        <div class="h-2 bg-gray-300 rounded col-span-1"></div>
                                                    </div>
                                                    <div class="h-2 bg-gray-300 rounded"></div>
                                                </div> */}
                                </div>
                            </div>
                        ) : (
                            <CardContent style={{ flexGrow: 1 }}>
                                <Stack>
                                    <Typography sx={{ fontSize: 21 }} color="text.secondary" gutterBottom>
                                        Papperoger Stats
                                    </Typography>
                                    <Stack>
                                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                            <ListItem sx={{ paddingY: 0.6, m: 0, p: 0 }}>
                                                <Stack flex={1} direction='row' alignItems='center' justifyContent='space-between'>
                                                    <ListItemAvatar>
                                                        <Avatar alt="best agent" src={images.businessman} className="topbarIconContainer" />
                                                    </ListItemAvatar>
                                                    <ListItemText primary="Top Agent" secondary={topAgent} sx={{ ml: 1 }} />
                                                    {/* <ListItemText primary="Top Agent" secondary="Mustafa Rabi" sx={{ml: 1}} /> */}
                                                    <Box alignItems='center' justifyItems='center' sx={{ backgroundColor: colors.lightGrey, borderRadius: 2, alignItems: 'center', justifyItems: 'center', p: 1 }}>
                                                        <div style={{ textAlign: 'center', fontSize: 14 }}>+{topAgentRatio.toFixed(1)}%</div>
                                                    </Box>
                                                </Stack>
                                            </ListItem>
                                            <ListItem sx={{ paddingY: 0.6, mt: 0.8, p: 0 }}>
                                                <Stack flex={1} direction='row' alignItems='center' justifyContent='space-between'>
                                                    <ListItemAvatar>
                                                        <Avatar alt="best agent" src={images.businessman} className="topbarIconContainer" />
                                                    </ListItemAvatar>
                                                    <ListItemText primary="Best User" secondary={bestUser} sx={{ ml: 1 }} />
                                                    <Box alignItems='center' justifyItems='center' sx={{ backgroundColor: colors.lightGrey, borderRadius: 2, alignItems: 'center', justifyItems: 'center', p: 1 }}>
                                                        <div style={{ textAlign: 'center', fontSize: 14 }}>+{bestUserRatio.toFixed(1)}%</div>
                                                    </Box>
                                                </Stack>
                                            </ListItem>
                                            <ListItem sx={{ paddingY: 0.6, mt: 0.8, p: 0 }}>
                                                <Stack flex={1} direction='row' alignItems='center' justifyContent='space-between'>
                                                    <ListItemAvatar>
                                                        <Avatar alt="best agent" src={images.businessman} className="topbarIconContainer" />
                                                    </ListItemAvatar>
                                                    <ListItemText primary="Most Respected Agent" secondary={bestAgentRatingName} sx={{ ml: 1 }} />
                                                    <Stack direction='row' alignItems='center' justifyItems='center' spacing={0.5} sx={{ backgroundColor: colors.lightGrey, borderRadius: 2, alignItems: 'center', justifyItems: 'center', p: 1 }}>
                                                        <div style={{ textAlign: 'center', fontSize: 14 }}>{bestAgentRating.toFixed(1)}</div>
                                                        <StarBorder />
                                                    </Stack>
                                                </Stack>
                                            </ListItem>
                                            <ListItem sx={{ paddingY: 0.60, mt: 0.85, p: 0 }}>
                                                <Stack flex={1} direction='row' alignItems='center' justifyContent='space-between'>
                                                    <ListItemAvatar>
                                                        <Avatar alt="best agent" src={images.businessman} className="topbarIconContainer" />
                                                    </ListItemAvatar>
                                                    <ListItemText primary="Most Respected User" secondary={bestUserRatingName} sx={{ ml: 1 }} />
                                                    <Stack direction='row' alignItems='center' justifyItems='center' spacing={0.5} sx={{ backgroundColor: colors.lightGrey, borderRadius: 2, alignItems: 'center', justifyItems: 'center', p: 1 }}>
                                                        <div style={{ textAlign: 'center', fontSize: 14 }}>{bestUserRating.toFixed(1)}</div>
                                                        <StarBorder />
                                                    </Stack>
                                                </Stack>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        )
                    }

                </Card>
                {/* </Grid> */}
                {/* <Grid item xs={12} md={4}> */}
                <Card style={{ flex: 1 }}>
                    {
                        loading === HTTP_STATUS.PENDING ? (
                        // true ? (
                            <div class="animate-pulse flex p-7">

                                <div class="flex-1 space-y-8">
                                    <div class="h-5 bg-gray-300 rounded w-1/2"></div>
                                    <div class="h-64 bg-gray-300 rounded"></div>
                                    {/* <div class="h-2 bg-gray-300 rounded"></div> */}
                                    {/* <div class="space-y-3">
                                                    <div class="grid grid-cols-3 gap-4">
                                                        <div class="h-2 bg-gray-300 rounded col-span-2"></div>
                                                        <div class="h-2 bg-gray-300 rounded col-span-1"></div>
                                                    </div>
                                                    <div class="h-2 bg-gray-300 rounded"></div>
                                                </div> */}
                                </div>
                            </div>
                        ) : (
                            <CardContent style={{ flexGrow: 1 }}>
                                <Typography sx={{ fontSize: 21 }} color="text.secondary" gutterBottom>
                                    Profit Overview
                                </Typography>
                                <ResponsiveContainer width="100%" height="100%" minHeight={260}>
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={data}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        {/* <Legend /> */}
                                        <Bar dataKey="Profit" fill="#8884d8" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </CardContent>
                        )
                    }

                    {/* <CardActions>
                            <Button size="small" onClick={() => console.log(adminProfile)}>See More</Button>
                        </CardActions> */}
                </Card>
            </div>
            {/* </Grid> */}
            {/* </Grid> */}

            {/* <Card sx={{ minWidth: 275 }} style={{flex: 1}}>
        <CardContent style={{flexGrow: 1}}>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Revenue
            </Typography>
            <Typography variant="h5" component="div">
            <span className="featuredMoney">$4,324</span>
                <span className="featuredMoneyRate">-1.4 <ArrowDownward /></span>
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Compared to last month
            </Typography>
            <Typography variant="body2">
            well meaning and kindly.
            <br />
            {'"a benevolent smile"'}
            </Typography>
        </CardContent>
        <CardActions>
            <Button size="small">See More</Button>
        </CardActions>
        </Card> */}

            {/* <div className="featuredItem">
            <span className="featuredTitle">Revenue</span>
            <div className="featuredMoneyContainer">
                <span className="featuredMoney">$2,324</span>
                <span className="featuredMoneyRate">-11.4 <ArrowDownward /></span>
            </div>
            <span className="featuredSub">Compared to last month</span>
        </div>

        <div className="featuredItem">
            <span className="featuredTitle">Sales</span>
            <div className="featuredMoneyContainer">
                <span className="featuredMoney">$4,324</span>
                <span className="featuredMoneyRate">-1.4 <ArrowDownward /></span>
            </div>
            <span className="featuredSub">Compared to last month</span>
        </div>

        <div className="featuredItem">
            <span className="featuredTitle">Cost</span>
            <div className="featuredMoneyContainer">
                <span className="featuredMoney">$3,324</span>
                <span className="featuredMoneyRate">3.4 <ArrowUpward /></span>
            </div>
            <span className="featuredSub">Compared to last month</span>
        </div> */}
        </div>
    );
}
