import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { myHistory } from "../utils/history"
import { HTTP_STATUS } from '../utils/constants'
import {format} from 'date-fns'
const { REACT_APP_API_URL } = process.env

export const getFoodOrders = createAsyncThunk(
    //reducer name / method name
    'foodOrder/getFoodOrders',
    async (data, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.get(`${REACT_APP_API_URL}food/orders`, {
            params: {
                page: data.page,
                pageSize: data.pageSize,
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    // console.log('buyOrdersThisMonth: ', result.data.buyOrdersThisMonth)
                    // console.log('buyOrdersSubOne: ', result.data.buyOrdersSubOne)
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const getLastFoodOrders = createAsyncThunk(
    //reducer name / method name
    'foodOrder/getLastFoodOrders',
    async (thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.get(`${REACT_APP_API_URL}food/orders/last`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    // console.log('buyOrdersThisMonth: ', result.data.buyOrdersThisMonth)
                    // console.log('buyOrdersSubOne: ', result.data.buyOrdersSubOne)
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const getSelectedFoodOrder = createAsyncThunk(
    //reducer name / method name
    'buyOrder/getSelectedFoodOrder',
    async (selectedFoodOrderId, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.get(`${REACT_APP_API_URL}foodorder`, {
            params: {
                id: selectedFoodOrderId
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                // console.log('res', result)
                if (result.data.success) {
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const updateFoodOrder = createAsyncThunk(
    //reducer name / method name
    'foodOrder/updateFoodOrder',
    async (buyOrder, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.put(`${REACT_APP_API_URL}foodorder`, buyOrder, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    myHistory.replace('/food-orders')
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const deleteFoodOrder = createAsyncThunk(
    //reducer name / method name
    'user/deleteFoodOrder',
    async (id, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.delete(`${REACT_APP_API_URL}foodorder`, {
            params: {
                id
            }
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(result => {
                console.log(result.data)
                if (result.data.success) {
                    // console.log('thunkAPI', thunkAPI)
                    // dispatch(getUsers())
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const postBuyOrder = createAsyncThunk(
    //reducer name / method name
    'buyOrder/postBuyOrder',
    async (buyOrder, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.post(`${REACT_APP_API_URL}buyorder`, buyOrder, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    myHistory.replace(`/food-orders`)
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    // return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                return result.data
            })
            .catch(err => console.log(err))
    }
)

const mapFoodOrders = foodOrders => {
    return foodOrders?.map((item, i) => {
        const newData = {
            id: i + 1,
            origId: item._id,
            merchant: item.merchant?.merchantName ? item.merchant?.merchantName : 'Unknown',
            merchantId: item.merchant?._id,
            user: item.user?.firstName ? item.user?.firstName + ' ' + item.user?.lastName : 'Unknown',
            userId: item.user?._id,
            cart: item?.cart?.map(item => item?.foodItem?.itemName).join(', '),
            // request: item.request,
            placeName: item.placeName,
            placeAddress: item.placeAddress,
            userAddress: item.userAddress,
            // price: item.totalPayment,
            totalPayment: `RM ${Number(item?.totalPayment).toFixed(2)}`,
            price: item.totalPayment,
            isAgentFound: item.isAgentFound,
            paymentMethod: item.paymentMethod,
            orderNo: item.orderNo,
            status: item.status,
            merchantProfilePhoto: item.merchant?.profilePhoto,
            userProfilePhoto: item.user?.profilePhoto,
            createdAt: format(new Date(item.createdAt), 'dd-MM-yyyy p'),
        }
        return newData
    });
}

const initialState = {
    loading: null,
    getFoodOrdersStatus: '',
    postBuyOrderStatus: '',
    updateFoodOrderStatus: '',
    deleteFoodOrderStatus: '',
    getLastFoodOrdersStatus: '',
    selectedFoodOrderId: '',
    foodOrders: [],
    foodOrder: {},
    lastFoodOrders: [],
    message: '',
    profitThisMonth: '',
    profitSubOne: '',
    profitSubTwo: '',
    profitSubThree: '',
    profitSubFour: '',
    profitSubFive: '',
    foodOrdersCount: 0,
}

export const buyOrderSlice = createSlice({
    name: 'buyOrder',
    initialState,
    reducers: {
        setPostBuyOrderStatus: (state, action) => {
            state.postBuyOrderStatus = ''
        },
        setSelectedFoodOrderId: (state, action) => {
            state.selectedFoodOrderId = action.payload
        },
        setUpdateFoodOrderStatus: (state, action) => {
            state.updateFoodOrderStatus = ''
        },
        setGetLastFoodOrdersStatus: (state, action) => {
            state.getLastFoodOrdersStatus = ''
        },
        clearBuyOrderLoading: (state) => {
            state.loading = null
        }
    },
    extraReducers: {
        [getFoodOrders.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.getBuyOrdersStatus = 'loading'
        },
        [getFoodOrders.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.getBuyOrdersStatus = 'success';
            state.profitThisMonth = action.payload.foodOrdersThisMonth.reduce((acc, curr) => {
                return acc + Number(curr.offer)
            }, 0);
            state.profitSubOne = action.payload.foodOrdersSubOne.reduce((acc, curr) => {
                return acc + Number(curr.offer)
            }, 0);
            state.profitSubTwo = action.payload.foodOrdersSubTwo.reduce((acc, curr) => {
                return acc + Number(curr.offer)
            }, 0);
            state.profitSubThree = action.payload.foodOrdersSubThree.reduce((acc, curr) => {
                return acc + Number(curr.offer)
            }, 0);
            state.profitSubFour = action.payload.foodOrdersSubFour.reduce((acc, curr) => {
                return acc + Number(curr.offer)
            }, 0);
            state.profitSubFive = action.payload.foodOrdersSubFive.reduce((acc, curr) => {
                return acc + Number(curr.offer)
            }, 0);
            state.foodOrders = mapFoodOrders(action.payload.foodOrders)
            state.foodOrdersCount = action.payload.foodOrdersCount
            // state.buyOrders = action.payload.buyOrders.map((item, i) => {
            //     const newData = {
            //         id: i + 1,
            //         origId: item._id,
            //         agent: item.agent?.firstName ? item.agent?.firstName + ' ' + item.agent?.lastName : 'Unknown',
            //         agentId: item.agent?._id,
            //         user: item.user?.firstName ? item.user?.firstName + ' ' + item.user?.lastName : 'Unknown',
            //         userId: item.user?._id,
            //         request: item.request,
            //         placeName: item.placeName,
            //         placeAddress: item.placeAddress,
            //         userAddress: item.userAddress,
            //         offer: item.offer,
            //         totalPayment: item.totalPayment,
            //         isAgentFound: item.isAgentFound,
            //         paymentMethod: item.paymentMethod,
            //         orderNo: item.orderNo,
            //         status: item.status,
            //         agentProfilePhoto: item.agent?.profilePhoto,
            //         userProfilePhoto: item.user?.profilePhoto,
            //     }
            //     return newData
            // });
        },
        [getFoodOrders.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.getBuyOrdersStatus = 'failed';
            state.message = action.payload;
        },
        [getSelectedFoodOrder.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [getSelectedFoodOrder.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.status = 'success'
            state.foodOrder = action.payload.foodOrder;
        },
        [getSelectedFoodOrder.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.status = 'failed';
            state.message = action.payload;
        },
        [updateFoodOrder.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.updateBuyOrderStatus = 'loading'
        },
        [updateFoodOrder.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.updateBuyOrderStatus = 'success';
            // state.users = action.payload.user;
        },
        [updateFoodOrder.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.updateBuyOrderStatus = 'failed';
            state.message = action.payload;
        },
        [postBuyOrder.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.postBuyOrderStatus = 'loading'
        },
        [postBuyOrder.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.postBuyOrderStatus = 'success'
        },
        [postBuyOrder.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.postBuyOrderStatus = 'failed'
            state.message = action.payload;
        },
        [deleteFoodOrder.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.deleteBuyOrderStatus = 'loading'
        },
        [deleteFoodOrder.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.foodOrders = mapFoodOrders(action.payload.foodOrders)
            // state.deleteBuyOrderStatus = 'success'
        },
        [deleteFoodOrder.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.deleteBuyOrderStatus = 'failed'
            state.message = action.payload;
        },
        [getLastFoodOrders.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.getLastBuyOrdersStatus = 'loading'
        },
        [getLastFoodOrders.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.getLastBuyOrdersStatus = 'success'
            state.lastFoodOrders = action.payload.lastFoodOrders
        },
        [getLastFoodOrders.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.getLastBuyOrdersStatus = 'failed'
            state.message = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setPostBuyOrderStatus, setSelectedFoodOrderId, setUpdateFoodOrderStatus, setGetLastFoodOrdersStatus, clearBuyOrderLoading } = buyOrderSlice.actions
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default buyOrderSlice.reducer