import React, { useEffect } from 'react';
import './widgetLg.css'
import { images } from '../../assets'
import { Card, Avatar, CardContent, Typography, Chip, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getLastFoodOrders, setGetLastFoodOrdersStatus } from '../../redux/foodOrder';
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { HTTP_STATUS } from '../../utils/constants'

function createData(User, Date, Amount, Status) {
  return { User, Date, Amount, Status };
}

// const rows = [
//   createData('Mustafa Rabi', '06 Feb 2022', 'RM22.00', 'Delivered'),
//   createData('Din Mustafar', '16 Feb 2022', 'RM32.00', 'On the way'),
//   createData('Big Boy', '11 Feb 2022', 'RM12.00', 'Canceled'),
//   createData('Invisible Team', '03 Feb 2022', 'RM41.00', 'Completed'),
// ];

export default function WidgetLg() {
  const dispatch = useDispatch()
  const { lastFoodOrders, loading } = useSelector(state => state.foodOrder)

  useEffect(() => {
    dispatch(getLastFoodOrders())

    return () => dispatch(setGetLastFoodOrdersStatus())
  }, [])

  let rows = []

  if (loading === HTTP_STATUS.FULFILLED) {
    rows = lastFoodOrders?.map(item => {
      return createData(item.user.firstName + ' ' + item.user.lastName, format(new Date(item?.createdAt), 'dd MMM yyyy'), item.totalPayment, item.status)
    })

    console.log('rows', rows)
  }


  return (
    <div className='basis-1/2' >
      <Card style={{ flex: 1 }}>
        {
          loading === HTTP_STATUS.PENDING ? (
            // true ? (
            <div class="animate-pulse flex p-7">

              <div class="flex-1 space-y-8">
                <div class="h-4 bg-gray-300 rounded w-1/2"></div>
                <div class="h-4 bg-gray-300 rounded"></div>
                <div class="h-4 bg-gray-300 rounded"></div>
                <div class="h-4 bg-gray-300 rounded"></div>
                <div class="h-4 bg-gray-300 rounded"></div>
                <div class="h-4 bg-gray-300 rounded"></div>
              </div>
            </div>
          ) : (
            <CardContent style={{ flexGrow: 1 }}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Latest Orders
              </Typography>

              {/* <TableContainer component={Paper}> */}
              <TableContainer>
                <Table aria-label="simple table">
                  {/* <Table sx={{ minWidth: 650 }} aria-label="simple table"> */}
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Dessert (100g serving)</TableCell> */}
                      <TableCell sx={{ fontWeight: 'bold' }} align="right">User</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }} align="right">Date</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }} align="right">Amount</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }} align="right">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <Stack direction='row' alignItems='center'>
                            <Avatar alt="Mustafa Rabi" src={images.businessman} className="topbarIconContainer" />
                            <Typography variant='body1'>{row.User}</Typography>

                          </Stack>
                        </TableCell>
                        {/* <TableCell align="right">{row.User}</TableCell> */}
                        <TableCell align="right">{row.Date}</TableCell>
                        <TableCell align="right">RM {row.Amount}</TableCell>
                        <TableCell align="right"><Chip label={row.Status} color={row.Status === 'Completed' ? 'success' : row.Status === 'Processing' || row.Status === 'Preparing' || row.Status === 'Delivering' ? 'warning' : row.Status === 'Pending' ? 'info' : 'error'} /></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          )
        }

      </Card>
    </div>
  );
}