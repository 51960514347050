import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { HTTP_STATUS } from '../utils/constants'
const { REACT_APP_API_URL } = process.env

export const getAgentRatings = createAsyncThunk(
    //reducer name / method name
    'rating/getAgentRatings',
    async (thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.get(`${REACT_APP_API_URL}agent/ratings`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                return result.data
            })
            .catch(err => console.log(err))
    }
)

export const getUserRatings = createAsyncThunk(
    //reducer name / method name
    'rating/getUserRatings',
    async (thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.get(`${REACT_APP_API_URL}user/ratings`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                return result.data
            })
            .catch(err => console.log(err))
    }
)

const initialState = {
    getAgentRatingsStatus: '',
    getUserRatingsStatus: '',
    agentRatings: [],
    userRatings: [],
    message: '',
    loading: null,
}

export const ratingSlice = createSlice({
    name: 'rating',
    initialState,
    reducers: {
        clearRatingLoading: (state) => {
            state.loading = null
        }
        // setPostBuyOrderStatus: (state, action) => {
        //     state.postBuyOrderStatus = ''
        // },
    },
    extraReducers: {
        [getAgentRatings.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.getAgentRatingsStatus = 'loading'
        },
        [getAgentRatings.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.getAgentRatingsStatus = 'success'
            state.agentRatings = action.payload.agentRatings;
        },
        [getAgentRatings.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.getAgentRatingsStatus = 'failed';
            state.message = action.payload;
        },
        [getUserRatings.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.getUserRatingsStatus = 'loading'
        },
        [getUserRatings.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.getUserRatingsStatus = 'success'
            state.userRatings = action.payload.userRatings;
        },
        [getUserRatings.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.getUserRatingsStatus = 'failed';
            state.message = action.payload;
        },
  },
})

// Action creators are generated for each case reducer function
export const { clearRatingLoading } = ratingSlice.actions

export default ratingSlice.reducer