import React from 'react';
import './sidebar.css';
import { List, ListItemButton, ListItemText, ListItemIcon, ListSubheader, Drawer, IconButton, Box } from '@mui/material';
import { Menu, ExpandMore, StarBorder, Home, Analytics, PointOfSale, Person, Work, ShoppingBag, AttachMoney, DeliveryDining, Send, Feedback, Restaurant, Chat, Phone, Label } from '@mui/icons-material';
import { MessageOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setMobileOpen } from '../../redux/admin';
import { images, colors } from '../../assets'

const drawerWidth = 256;

export default function Sidebar(props) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { mobileOpen } = useSelector(state => state.admin)
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const { window } = props;
    // const [mobileOpen, setMobileOpen] = React.useState(false);

    // const handleDrawerToggle = () => {
    //     setMobileOpen(!mobileOpen);
    // };

    const handleNavigate = (target) => {
        navigate(target)
        dispatch(setMobileOpen(false))
    }

    const container = window !== undefined ? () => window().document.body : undefined;

    const drawer = (
        <div>
            <div className="flex-row flex items-center gap-4 justify-center">
                <img src={images.logo} alt="Logo" className='object-contain h-24 w-12' />
                <span className='text-2xl text-blue-900'>PAPPEROGER</span>
            </div>
            <div >
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" className="nested-list-subheader">
                            Dashboard
                        </ListSubheader>
                    }
                >
                    <ListItemButton onClick={() => handleNavigate('/')}>
                        <ListItemIcon>
                            <Home />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>

                    {/* <ListItemButton>
                        <ListItemIcon>
                            <Analytics />
                        </ListItemIcon>
                        <ListItemText primary="Analytics" />
                    </ListItemButton> */}
                    {/* <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <PointOfSale />
                        </ListItemIcon>
                        <ListItemText primary="Sales" />
                    </ListItemButton> */}
                </List>

                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" className="nested-list-subheader">
                            Lists
                        </ListSubheader>
                    }
                >
                    <ListItemButton onClick={() => handleNavigate('/users')}>
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary="Users" />
                    </ListItemButton>
                    <ListItemButton onClick={() => handleNavigate('/agents')}>
                        <ListItemIcon>
                            <DeliveryDining />
                        </ListItemIcon>
                        <ListItemText primary="Agents" />
                    </ListItemButton>
                    <ListItemButton onClick={() => handleNavigate('/merchants')}>
                        <ListItemIcon>
                            <Restaurant />
                        </ListItemIcon>
                        <ListItemText primary="Merchants" />
                    </ListItemButton>
                    <ListItemButton onClick={() => handleNavigate('/food-orders')}>
                        <ListItemIcon>
                            <ShoppingBag />
                        </ListItemIcon>
                        <ListItemText primary="Orders" />
                    </ListItemButton>
                    {/* <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <AttachMoney />
                        </ListItemIcon>
                        <ListItemText primary="Transactions" />
                    </ListItemButton> */}
                    {/* <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <BarChart />
                        </ListItemIcon>
                        <ListItemText primary="Reports" />
                    </ListItemButton> */}
                </List>

                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" className="nested-list-subheader">
                            Messages
                        </ListSubheader>
                    }
                >
                    {/* <ListItemButton>
                        <ListItemIcon>
                            <Notifications />
                        </ListItemIcon>
                        <ListItemText primary="System Messages" />
                    </ListItemButton> */}
                    {/* <ListItemButton>
                        <ListItemIcon>
                            <Feedback />
                        </ListItemIcon>
                        <ListItemText primary="Feedback" />
                    </ListItemButton> */}
                    {/* <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <Chat />
                        </ListItemIcon>
                        <ListItemText primary="Support" />
                    </ListItemButton> */}
                    <ListItemButton onClick={() => handleNavigate('/notification')}>
                        <ListItemIcon>
                            <Send />
                        </ListItemIcon>
                        <ListItemText primary="Notifications" />
                    </ListItemButton>
                    <ListItemButton onClick={() => handleNavigate('/user-feedback')}>
                        <ListItemIcon>
                            <Feedback />
                        </ListItemIcon>
                        <ListItemText primary="User Feedback" />
                    </ListItemButton>
                    <ListItemButton onClick={() => handleNavigate('/agent-feedback')}>
                        <ListItemIcon>
                            <Feedback />
                        </ListItemIcon>
                        <ListItemText primary="Agent Feedback" />
                    </ListItemButton>
                    <ListItemButton onClick={() => handleNavigate('/customer-support')}>
                        <ListItemIcon>
                            <Chat />
                        </ListItemIcon>
                        <ListItemText primary="Customer Support" />
                    </ListItemButton>
                </List>

                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" className="nested-list-subheader">
                            Promotions
                        </ListSubheader>
                    }
                >
                    <ListItemButton onClick={() => handleNavigate('/coupons')}>
                        <ListItemIcon>
                            <Label />
                        </ListItemIcon>
                        <ListItemText primary="Coupons" />
                    </ListItemButton>
                </List>

                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" className="nested-list-subheader">
                            Settings
                        </ListSubheader>
                    }
                >
                    <ListItemButton onClick={() => handleNavigate('/support-number')}>
                        <ListItemIcon>
                            <Phone />
                        </ListItemIcon>
                        <ListItemText primary="Support Number" />
                    </ListItemButton>
                </List>

                {/* <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" className="nested-list-subheader">
                            Staff
                        </ListSubheader>
                    }
                >
                    <ListItemButton>
                        <ListItemIcon>
                            <People />
                        </ListItemIcon>
                        <ListItemText primary="Manage" />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <StackedLineChart />
                        </ListItemIcon>
                        <ListItemText primary="Analytics" />
                    </ListItemButton>
                    <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <Report />
                        </ListItemIcon>
                        <ListItemText primary="Reports" />
                    </ListItemButton>
                </List> */}
            </div>
        </div>
    )

    return (
        <div className='basis-64'>
            {/* <IconButton
                color='inherit'
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ ml: 1 }}
            >
                <Menu />
            </IconButton> */}

            <Box
                component="nav"
                // sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={() => dispatch(setMobileOpen(false))}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        // display: { xs: 'block', sm: 'none' },
                        // display: { sm: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                {/* <div className='w-46 h-full bg-blue-800'>
                    test
                </div> */}
                <Drawer
                    // variant="permanent"
                    sx={{
                        // display: { xs: 'none', sm: 'none', md: 'none' },
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    variant="persistent"
                    // anchor="left"
                    // open={open}
                    open

                    className='hidden xl:flex'
                >
                    {drawer}
                </Drawer>
            </Box>
        </div>
    );
}
