import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Stack, Alert } from '@mui/material'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { postLogin, setMessage, clearLoading } from '../../redux/admin'
// import {useNavigate} from 'react-router-dom'
import { images, colors } from '../../assets'
import { HTTP_STATUS } from '../../utils/constants'
// import { useState } from 'react';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://papperoger.com/">
        Papperoger
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignInSide() {

  // REDUX SECTION
  const { adminProfile, status, message, loading } = useSelector(state => state.admin)
  const dispatch = useDispatch()

  // const navigate = useNavigate()
  const [loginError, setLoginError] = useState(false)

  useEffect(() => {
    dispatch(clearLoading())
    dispatch(setMessage(''))
  }, [])
  // const loginAdmin = async (credentials) => {
  //   // const formData = new FormData()
  //   // formData.append('firstName', )
  //   try {
  //     return await axios.post('http://localhost:1337/api/admin/login', credentials)
  //     .then(result => {
  //       // console.log(result)
  //       if (!result.data.success) {
  //         setLoginError(true)
  //       } else {
  //         setLoginError(false)
  //       }
  //       return result.data
  //     })
  //     .catch(err => console.log(err))
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });
    const adminData = {
      email: data.get('email'),
      password: data.get('password'),
    }

    if (!data.get('email') || !data.get('password')) return;
    dispatch(postLogin(adminData))
    // console.log(status)
    console.log(data.get('email'), data.get('password'))
    // console.log(adminData)
    // if (adminData.token) {
    //   dispatch(setUsername(data.get('email')))
    //   setToken(adminData.token)
    // }
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   // eslint-disable-next-line no-console
  //   console.log({
  //     email: data.get('email'),
  //     password: data.get('password'),
  //   });
  //   const token = {
  //       email: data.get('email'),
  //       password: data.get('password'),
  //     }
  //   setToken(token)
  //   // navigate('Login')
  // };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img alt='logo' src={images.logo} width={100} style={{ marginBottom: '10%' }} />
            <Stack direction='row' spacing={1} alignItems='center' >
              {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}
              <Avatar sx={{ m: 1, bgcolor: colors.papperogerSecondary }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
            </Stack>

            {
              loading === HTTP_STATUS.REJECTED && message && (
                <Alert variant='standard' severity="error" sx={{ marginTop: 3, width: '100%' }}>
                  {message}
                </Alert>
              )
            }


            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading === HTTP_STATUS.PENDING ? true : false}
              >
                {
                  loading === HTTP_STATUS.PENDING &&
                  <div class="animate-spin h-5 w-5 mr-3 border-b-2 rounded-full border-blue-900" />
                }

                Sign In
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}