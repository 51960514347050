import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { message } from 'antd'
import axios from 'axios'
import { myHistory } from "../utils/history"
import { HTTP_STATUS } from '../utils/constants'
const { REACT_APP_API_URL } = process.env

export const getUsers = createAsyncThunk(
    //reducer name / method name
    'user/getUsers',
    async (thunkAPI) => {
        // console.log(`${REACT_APP_API_URL}users`)
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.get(`${REACT_APP_API_URL}users`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(async result => {
                if (result.data.success) {
                    // console.log('users: ', result.data.users)
                    // const mappedUsers = await result.data.users.map((item, i) => {
                    //         return {
                    //             id: i + 1,
                    //             origId: item._id,
                    //             user: item.firstName + ' ' + item.lastName,
                    //             email: item.email,
                    //             mobile: item.mobilePhone,
                    //             transactions: 'RM244',
                    //             profilePhoto: item.profilePhoto,
                    //         }
                    //     })
                    //     console.log('users: ', mappedUsers)
                    // thunkAPI.dispatch(setUsers(result.data.users))
                    // thunkAPI.dispatch(setUsers(result.data.users.map((item, i) => {
                    //     return {
                    //         id: i + 1,
                    //         origId: item._id,
                    //         user: item.firstName + ' ' + item.lastName,
                    //         email: item.email,
                    //         mobile: item.mobilePhone,
                    //         transactions: 'RM244',
                    //         profilePhoto: item.profilePhoto,
                    //     }
                    // }
                    // )))
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                    // return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const getUser = createAsyncThunk(
    //reducer name / method name
    'user/getUser',
    async (id, thunkAPI) => {
        // console.log(`${REACT_APP_API_URL}users`)
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.get(`${REACT_APP_API_URL}user`, {
            params: {
                id
            }
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(async result => {
                if (result.data.success) {
                    console.log(result.data)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const getLastUsers = createAsyncThunk(
    //reducer name / method name
    'user/getLastUsers',
    async (thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.get(`${REACT_APP_API_URL}users/last`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(async result => {
                console.log(result)
                if (result.data.success) {
                    return result.data
                    // return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const getSelectedUser = createAsyncThunk(
    //reducer name / method name
    'user/getSelectedUser',
    async (selectedUserId, thunkAPI) => {
        // const {email, password} = userData
        // const token = sessionStorage.getItem('token')
        return await axios.get(`${REACT_APP_API_URL}user`, {
            params: {
                id: selectedUserId
            },
            // headers: {
            //     Authorization: `Bearer ${token}`
            // }
        })
            .then(result => {
                // console.log('res', result)
                if (result.data.success) {
                    console.log('working', result.data)
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const updateUser = createAsyncThunk(
    //reducer name / method name
    'user/updateUser',
    async (userdata, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.put(`${REACT_APP_API_URL}user`, userdata, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    // myHistory.replace('/users')
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const updateUserProfilePhoto = createAsyncThunk(
    //reducer name / method name
    'admin/updateUserProfilePhoto',
    async (userdata, thunkAPI) => {

        // console.log('profilePhoto', profilePhoto)
        // const updateAdminProfile = async (userdata, thunkAPI) => {
        const token = sessionStorage.getItem('token')
        return await axios.put(`${REACT_APP_API_URL}user/photo`, userdata, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                console.log(result)
                if (result.data.success) {
                    message.success('User profile has been updated successfully!');
                    myHistory.replace('/users')
                    // myHistory.replace('/my-account')
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
        // }

    }
)

export const deleteUser = createAsyncThunk(
    //reducer name / method name
    'user/deleteUser',
    async (id, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.delete(`${REACT_APP_API_URL}user`, {
            params: {
                id
            }
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                console.log(result.data)
                if (result.data.success) {
                    // console.log('thunkAPI', thunkAPI)
                    // dispatch(getUsers())
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const createUser = createAsyncThunk(
    //reducer name / method name
    'user/createUser',
    async (userdata, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.post(`${REACT_APP_API_URL}user/register`, userdata, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    myHistory.replace(`/users`)
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

// export const getUserAddress = createAsyncThunk(
//     //reducer name / method name
//     'user/getUserAddress',
//     async (selectedUserId, thunkAPI) => {
//         // const {email, password} = userData
//         return await axios.get(`${REACT_APP_API_URL}/user/address`, {
//             params: {
//                 id: selectedUserId
//             },
//             // headers: {
//             //     Authorization: `Bearer ${token}`
//             // }
//         })
//             .then(result => {
//                 // console.log('res', result)
//                 if (result.data.success) {
//                     // console.log('working', result.data)
//                     // setLoginError(true)
//                     // sessionStorage.setItem('token', result.data.token)
//                     // thunkAPI.dispatch(result.data.profile)
//                     return result.data
//                 } else {
//                     return thunkAPI.rejectWithValue(result.data.message)
//                     // setLoginError(false)
//                 }
//                 // return result.data
//             })
//             .catch(err => console.log(err))
//     }
// )

const mapUsers = (users) => {
    // console.log(users)
    return users.map((item, i) => {
        const newData = {
            id: i + 1,
            origId: item._id,
            user: item.firstName + ' ' + item.lastName,
            email: item.email,
            mobile: item.mobilePhone,
            profilePhoto: item.profilePhoto,
            userAddress: item.userAddress,
        }
        return newData
    });
}

const initialState = {
    // status: '',
    loading: null,
    updateUserStatus: '',
    createUserStatus: '',
    deleteUserStatus: '',
    getLastUsersStatus: '',
    users: [],
    user: null,
    lastUsers: [],
    // origUsers: [],
    message: '',
    selectedUserId: '',
    usersThisMonth: 0,
    usersSubOne: 0,
    usersSubTwo: 0,
    usersSubThree: 0,
    usersSubFour: 0,
    usersSubFive: 0,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setSelectedUserId: (state, action) => {
            state.selectedUserId = action.payload
        },
        setUpdateUserStatus: (state, action) => {
            state.updateUserStatus = ''
        },
        setCreateUserStatus: (state, action) => {
            state.createUserStatus = ''
        },
        setGetLastUsersStatus: (state, action) => {
            state.getLastUsersStatus = ''
        },
        setUsers: (state, action) => {
            state.users = action.payload
        },
        clearUserLoading: (state) => {
            state.loading = null
        }
    },
    extraReducers: {
        [getUsers.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [getUsers.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.users = action.payload;
            state.usersThisMonth = action.payload.usersThisMonth.length
            state.usersSubOne = action.payload.usersSubOne.length
            state.usersSubTwo = action.payload.usersSubTwo.length
            state.usersSubThree = action.payload.usersSubThree.length
            state.usersSubFour = action.payload.usersSubFour.length
            state.usersSubFive = action.payload.usersSubFive.length
            state.users = mapUsers(action.payload.users)
            // state.users = action.payload.users.map((item, i) => {
            //     const newData = {
            //       id: i+1,
            //       origId: item._id,
            //       user: item.firstName+' '+item.lastName,
            //       email: item.email,
            //       mobile: item.mobilePhone,
            //       profilePhoto: item.profilePhoto,
            //     }
            //     return newData
            //   });

            // state.users = '';

            // state.users = action.payload.users.map((item, i) => {
            //     const newData = {
            //         id: i + 1,
            //         origId: item._id,
            //         user: item.firstName + ' ' + item.lastName,
            //         email: item.email,
            //         mobile: item.mobilePhone,
            //         transactions: 'RM244',
            //         profilePhoto: item.profilePhoto,
            //     }
            //     return newData
            // });
        },
        [getUsers.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            state.message = action.payload;
        },
        [getSelectedUser.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [getSelectedUser.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.status = 'success'
            state.user = action.payload.selectedUser;
        },
        [getSelectedUser.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            state.message = action.payload;
        },
        [updateUser.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.updateUserStatus = 'loading'
        },
        [updateUser.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.updateUserStatus = 'success';
            // state.users = action.payload.user;
        },
        [updateUser.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            state.message = action.payload;
        },
        [createUser.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.createUserStatus = 'loading'
        },
        [createUser.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.createUserStatus = 'success';
            // state.users = action.payload.user;
        },
        [createUser.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            state.message = action.payload;
        },
        [deleteUser.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.deleteUserStatus = 'loading'
        },
        [deleteUser.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.users = mapUsers(action.payload.users)
            // state.users = action.payload.users;
        },
        [deleteUser.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            state.message = action.payload;
        },
        [getLastUsers.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.getLastUsersStatus = 'loading'
        },
        [getLastUsers.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.lastUsers = action.payload.lastUsers;
            // state.getLastUsersStatus = 'success';
        },
        [getLastUsers.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            state.message = action.payload;
        },
        [getUser.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.getLastUsersStatus = 'loading'
        },
        [getUser.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.user = action.payload.selectedUser;
            // state.getLastUsersStatus = 'success';
        },
        [getUser.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            state.message = action.payload;
        },
        [updateUserProfilePhoto.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
        },
        [updateUserProfilePhoto.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
        },
        [updateUserProfilePhoto.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            state.message = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setSelectedUserId, setUpdateUserStatus, setCreateUserStatus, setGetLastUsersStatus, setUsers, clearUserLoading } = userSlice.actions

export default userSlice.reducer