import React, { useEffect } from 'react';
import {
    Button,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Select,
    Switch,
    message
} from 'antd';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createCoupon, updateCoupon } from '../../redux/coupon'
import { format } from 'date-fns'
// import { HTTP_STATUS } from '../../utils/constants';

const { RangePicker } = DatePicker;

export default function NewCoupon() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { coupon, loading, crudOperation } = useSelector(state => state.coupon)
    const [messageApi, contextHolder] = message.useMessage();

    const successMessage = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
        });
    };

    const errorMessage = (msg) => {
        messageApi.open({
            type: 'error',
            content: msg,
        });
    };

    // useEffect(() => {
    //     dispatch(getSupportPhone())
    // }, [])

    const onFinish = (values) => {
        if (!values.status) {
            values.status = false
        }
        values.validFrom = values.duration[0].$d
        values.validTo = values.duration[1].$d
        values.id = coupon.origId
        // console.log('Success:', format(values.duration[0].$d, 'dd'));
        // console.log('Success:', format(values.duration[1].$d, 'dd'));
        console.log('Success:', values);

        try {
            const data = {
                values,
                successMessage,
                errorMessage,
            }

            // successMessage('dsdasdsd')

            if (crudOperation === 'create') {
                dispatch(createCoupon(data))
            } else {
                console.log('update')
                dispatch(updateCoupon(data))
            }

        } catch (error) {
            console.log(error)
        }

        // const data = {
        //     supportPhone: values.mobileNumber,
        //     successMessage,
        //     errorMessage,
        // }

        // dispatch(createCoupon(data))
        // success()
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    // //HANDLE SUBMIT
    // const handleSubmit = async event => {
    //     event.preventDefault()
    //     const data = new FormData(event.currentTarget)
    //     // console.log(data.get('firstName'))
    //     // console.log(event.currentTarget)

    //     try {
    //         // dispatch(createUser(data))
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    return (
        <div className='flex flex-col p-5 space-y-5 md:w-2/3' >
            {contextHolder}
            <div className='text-2xl font-semibold '>
                New Coupon
            </div>
            <Form
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 14,
                }}
                layout="horizontal"
                size={'large'}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={{
                    width: '100%',
                    // maxWidth: 600,
                }}
            >
                <Form.Item initialValue={crudOperation === 'update' ? coupon.code : null} label="Coupon Code" name="code" rules={[
                    {
                        required: true,
                        message: 'Please input the coupon code!',
                    },
                ]}>
                    <Input />
                </Form.Item>
                <Form.Item initialValue={crudOperation === 'update' ? coupon.discount : null} label="Discount Amount" name="discount" rules={[
                    {
                        required: true,
                        message: 'Please input the discount!',
                    },
                ]}>
                    <InputNumber />
                </Form.Item>
                <Form.Item initialValue={crudOperation === 'update' ? coupon.discountType : null} label="Discount Type" name="discountType" rules={[
                    {
                        required: true,
                        message: 'Please input the discount type!',
                    },
                ]}>
                    <Select>
                        <Select.Option value="percentage">Percentage</Select.Option>
                        <Select.Option value="fixed">Fixed</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item initialValue={crudOperation === 'update' ? coupon.quantity : null}  label="Quantity" name="quantity" rules={[
                    {
                        required: true,
                        message: 'Please input the quantity!',
                    },
                ]}>
                    <InputNumber />
                </Form.Item>
                {/* <Form.Item label="DatePicker">
                    <DatePicker />
                </Form.Item> */}
                <Form.Item label="Duration" name="duration" rules={[
                    {
                        required: true,
                        message: 'Please input the duration!',
                    },
                ]}>
                    <RangePicker />
                </Form.Item>

                <Form.Item initialValue={crudOperation === 'update' ? coupon.status : null}  label="Active" valuePropName="checked" name="status">
                    <Switch defaultChecked={false} />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 6,
                        span: 6,
                    }}
                // style={{
                //     width: 200
                // }}
                >
                    <Button htmlType="submit" block>{crudOperation === 'create' ? 'Create' : 'Update'}</Button>
                </Form.Item>
            </Form>
        </div>
    );
}
