import React, { useEffect } from 'react';
import './chart.css'
import { Card, CardContent, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useSelector, useDispatch } from 'react-redux';
import { getAgents } from '../../redux/agent';
import { getUsers } from '../../redux/user';
import { format, subMonths } from 'date-fns'
import { HTTP_STATUS } from '../../utils/constants'

const dataKey1 = 'Registered Users'
const dataKey2 = 'Registered Agents'

export default function Chart() {
  const dispatch = useDispatch()
  const { agentsThisMonth, agentsSubOne, agentsSubTwo, agentsSubThree, agentsSubFour, agentsSubFive, loading } = useSelector(state => state.agent)
  const { usersThisMonth, usersSubOne, usersSubTwo, usersSubThree, usersSubFour, usersSubFive, loading: userLoading } = useSelector(state => state.user)

  const data = [
    {
      name: format(subMonths(new Date(), 5), 'MMM'),
      [dataKey1]: usersSubFive,
      [dataKey2]: agentsSubFive,
    },
    {
      name: format(subMonths(new Date(), 4), 'MMM'),
      [dataKey1]: usersSubFour,
      [dataKey2]: agentsSubFour,
    },
    {
      name: format(subMonths(new Date(), 3), 'MMM'),
      [dataKey1]: usersSubThree,
      [dataKey2]: agentsSubThree,
    },
    {
      name: format(subMonths(new Date(), 2), 'MMM'),
      [dataKey1]: usersSubTwo,
      [dataKey2]: agentsSubTwo,
    },
    {
      name: format(subMonths(new Date(), 1), 'MMM'),
      [dataKey1]: usersSubOne,
      [dataKey2]: agentsSubOne,
    },
    {
      name: format(new Date(), 'MMM'),
      [dataKey1]: usersThisMonth,
      [dataKey2]: agentsThisMonth,
    },
  ];

  // const data = [
  //   {
  //     name: format(subMonths(new Date(), 5), 'MMM'),
  //     [dataKey1]: 11,
  //     [dataKey2]: 22,
  //   },
  //   {
  //     name: format(subMonths(new Date(), 4), 'MMM'),
  //     [dataKey1]: 23,
  //     [dataKey2]: 41,
  //   },
  //   {
  //     name: format(subMonths(new Date(), 3), 'MMM'),
  //     [dataKey1]: 22,
  //     [dataKey2]: 32,
  //   },
  //   {
  //     name: format(subMonths(new Date(), 2), 'MMM'),
  //     [dataKey1]: 41,
  //     [dataKey2]: 64,
  //   },
  //   {
  //     name: format(subMonths(new Date(), 1), 'MMM'),
  //     [dataKey1]: 56,
  //     [dataKey2]: 45,
  //   },
  //   {
  //     name: format(new Date(), 'MMM'),
  //     [dataKey1]: 45,
  //     [dataKey2]: 80,
  //   },
  // ];

  useEffect(() => {
    dispatch(getAgents())
    dispatch(getUsers())
  }, [])

  return (
    // <div className='chart' >
    // Chart
    // </div>
    <div className='p-4'>
      <Card>
        {
          loading && userLoading === HTTP_STATUS.PENDING ? (
          // true ? (
            <div class="animate-pulse flex p-7">
              <div class="flex-1 space-y-8">
                <div class="h-5 bg-gray-300 rounded w-1/4"></div>
                <div class="h-64 bg-gray-300 rounded"></div>
              </div>
            </div>
          ) : (
            <CardContent style={{ flexGrow: 1 }}>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                User & Agent Analysis
              </Typography>
              <ResponsiveContainer minHeight={260} width="100%" height="100%" >
                <LineChart
                  // width={500}
                  // height={300}
                  data={data}
                // margin={{
                //   top: 5,
                //   right: 30,
                //   left: 20,
                //   bottom: 5,
                // }}
                >
                  <CartesianGrid strokeDasharray="5 5" />
                  <XAxis dataKey="name" stroke="#8884d8" />
                  {/* <YAxis /> */}
                  <Tooltip />
                  {/* <Legend /> */}
                  <Line type="monotone" dataKey={dataKey1} stroke="#8884d8" activeDot={{ r: 8 }} />
                  <Line type="monotone" dataKey={dataKey2} stroke="#82ca9d" activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          )
        }

      </Card>
    </div>
  );
}
