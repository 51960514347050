import React, {useEffect} from 'react';
import './home.css'
import { FeaturedInfo, Chart, WidgetSm, WidgetLg } from '../../components';
import { Card, CardActions, CardContent, Typography, Button, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { clearBuyOrderLoading } from '../../redux/foodOrder';
import { clearUserLoading } from '../../redux/user';
import { clearAgentLoading } from '../../redux/agent';
import { clearRatingLoading } from '../../redux/rating';

export default function Home() {
  const dispatch = useDispatch()
  const {loading} = useSelector(state => state.foodOrder)

  useEffect(() => {
    // dispatch(clearBuyOrderLoading())
    // dispatch(clearUserLoading())
    // dispatch(clearAgentLoading())
    // dispatch(clearRatingLoading())
  }, [])

  useEffect(() => {
    console.log(loading)
  }, [])

  return (
    <div className='xl:basis-full justify-center items-center bg-gray-100'>
      <FeaturedInfo />
      <Chart />
      {/* <div className="homeWidget"> */}
      <div className='flex lg:flex-row flex-col gap-4 justify-center p-4'>
        <WidgetSm />
        <WidgetLg />
      </div>
    </div>
  );
}


// return (
//   <div className='flex'>
//     <FeaturedInfo />
//     <Chart />
//     {/* <div className="homeWidget"> */}
//     <div>
//       <Grid container spacing={2} marginTop={1}>
//         <Grid item xs={12} md={4}>
//           <WidgetSm />
//         </Grid>
//         <Grid item xs={12} md={8}>
//           <WidgetLg />
//         </Grid>
//       </Grid>
//     </div>
//   </div>
// );