import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { myHistory } from "../utils/history"
import { HTTP_STATUS } from '../utils/constants'
const { REACT_APP_API_URL } = process.env

export const getMerchants = createAsyncThunk(
    //reducer name / method name
    'merchant/getMerchants',
    async (thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.get(`${REACT_APP_API_URL}merchants`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    // console.log('agentsThisMonth', result.data.agentsThisMonth)
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const getSelectedMerchant = createAsyncThunk(
    //reducer name / method name
    'merchant/getSelectedMerchant',
    async (selectedMerchantId, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.get(`${REACT_APP_API_URL}merchant`, {
            params: {
                id: selectedMerchantId
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(result => {
                // console.log('res', result)
                if (result.data.success) {
                    // console.log(result.data.merchant)
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const updateMerchant = createAsyncThunk(
    //reducer name / method name
    'merchant/updateMerchant',
    async (userdata, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.put(`${REACT_APP_API_URL}merchant`, userdata, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    myHistory.replace('/merchants')
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

export const deleteMerchant = createAsyncThunk(
    //reducer name / method name
    'merchant/deleteMerchant',
    async ({merchantId, successMessage, errorMessage}, thunkAPI) => {
        // const {email, password} = userData
        const token = sessionStorage.getItem('token')
        return await axios.delete(`${REACT_APP_API_URL}merchant`, {
            params: {
                id: merchantId
            }
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                console.log(result.data)
                if (result.data.success) {
                    successMessage(result.data.message)
                    // console.log('thunkAPI', thunkAPI)
                    // dispatch(getUsers())
                    // setLoginError(true)
                    // sessionStorage.setItem('token', result.data.token)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    errorMessage(result.data.message)
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
                // return result.data
            })
            .catch(err => console.log(err))
    }
)

const mapMerchant = (merchant) => {
    return merchant.map((item, i) => {
        const newData = {
            id: i + 1,
            origId: item._id,
            merchant: item.merchantName,
            email: item.email,
            mobile: item.mobilePhone,
            merchantCategory: item.merchantCategory,
            storeOpen: item.storeOpen,
            active: item.active,
            logoUrl: item.logoUrl,
            foodCategoryCount: item.foodCategory.length,
            foodItemCount: item.foodItem.length,
        }
        return newData
    });
}

const initialState = {
    // status: '',
    loading: null,
    merchant: {},
    merchants: [],
    message: '',
    selectedMerchantId: '',
}

export const merchantSlice = createSlice({
    name: 'merchant',
    initialState,
    reducers: {
        setSelectedMerchantId: (state, action) => {
            state.selectedMerchantId = action.payload
        },
    },
    extraReducers: {
        [getMerchants.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [getMerchants.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.merchants = mapMerchant(action.payload.merchants)
        },
        [getMerchants.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.status = 'failed';
            state.message = action.payload;
        }, 
        [getSelectedMerchant.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [getSelectedMerchant.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.merchant = action.payload.merchant
        },
        [getSelectedMerchant.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.status = 'failed';
            state.message = action.payload;
        }, 
        [updateMerchant.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [updateMerchant.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
        },
        [updateMerchant.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.status = 'failed';
            state.message = action.payload;
        },
        [deleteMerchant.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [deleteMerchant.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.merchants = mapMerchant(action.payload.merchants)
        },
        [deleteMerchant.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.status = 'failed';
            state.message = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setSelectedMerchantId } = merchantSlice.actions

export default merchantSlice.reducer