import React, { useEffect, useLayoutEffect, useState } from 'react';
import { images } from '../../assets'
import { useNavigate } from 'react-router-dom'
import { Typography, Stack, Button, Card, CardContent, Grid, Avatar, TextField, Box, IconButton, Autocomplete } from '@mui/material';
import { Person, PhoneAndroid, AttachMoney, CalendarToday, LocationOn, ShoppingBag, Route, Work, CurrencyExchange, Numbers, PointOfSale, Restaurant } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { getSelectedFoodOrder, updateFoodOrder } from '../../redux/foodOrder'
import { HTTP_STATUS } from '../../utils/constants'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
// import {} from '../../redux/user'



// const position = {
//     lat: 24.4883,
//     lng: 54.37275
// }

export default function FoodOrder() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { selectedFoodOrderId, foodOrder, updateFoodOrderStatus, loading } = useSelector(state => state.foodOrder)

    const orderStatus = [
        { label: 'Processing', id: 1 },
        { label: 'Pending', id: 2 },
        { label: 'Preparing', id: 3 },
        { label: 'Delivering', id: 4 },
        { label: 'Completed', id: 5 },
        { label: 'Canceled', id: 6 },
        { label: 'Timeout', id: 7 },
    ]

    const containerStyle = {
        width: '100%',
        height: '400px',
    };

    const center = {
        lat: foodOrder?.userAddress?.coords?.latitude,
        lng: foodOrder?.userAddress?.coords?.longitude
        // latitudeDelta: 0.0322,
        // longitudeDelta: 0.0221,
    };

    const position = {
        lat: foodOrder?.userAddress?.coords?.latitude,
        lng: foodOrder?.userAddress?.coords?.longitude
    }

    // MAP SECTION
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyC6qbyU1QFl9j2ok5S-xYdgj94QFufeqR8"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        setTimeout(() => setMap(map))
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        // setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])


    // const orderPaymentMethods = [
    //     { label: 'Cash on Delivery', id: 1 },
    //     { label: 'Wallet', id: 2 },
    //     { label: 'Card', id: 3 },
    // ]

    useLayoutEffect(() => {
        dispatch(getSelectedFoodOrder(selectedFoodOrderId))
        console.log('foodOrder', foodOrder)
    }, [])

    // console.log(user)

    const Input = styled('input')({
        display: 'none',
    });

    const handleSubmit = async event => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        data.append('id', selectedFoodOrderId)
        console.log('status', data.get('status'))

        try {
            dispatch(updateFoodOrder(data))
            // const userData = {
            //     firstName: data.get('firstName'),
            //     lastName: data.get('lastName'),
            //     email: data.get('email'),
            //     mobilePhone: data.get('mobilePhone'),
            //     address: data.get('address'),
            // }

        } catch (error) {
            console.log(error)
        }

    }

    // useEffect(() => {
    //     if (updateBuyOrderStatus === 'success') {
    //         navigate('/buy-orders')
    //     }
    //     console.log('status ', updateBuyOrderStatus)
    // }, [updateBuyOrderStatus])

    return (
        <Stack className="user" paddingX={2}>
            <Stack direction='row' alignItems='center' justifyContent='space-between' >
                <Typography variant='h5' fontWeight='bold'>Edit Food Order</Typography>
                <Button variant='contained' onClick={() => navigate('/new-buy-order')}>
                    Create
                </Button>
            </Stack>

            <Grid container spacing={2} marginTop={1}>
                <Grid item xs={12} md={4}>
                    <Card sx={{ padding: 2, }}>
                        {
                            loading === HTTP_STATUS.PENDING ? (
                                // true ? (
                                <div class="animate-pulse flex space-x-4 py-7">

                                    <div class="flex-1 space-y-6">
                                        <div className='flex flex-row items-center space-x-3'>
                                            {/* <div class="rounded-full bg-gray-300 h-10 w-10"></div> */}
                                            <div class="h-2 bg-gray-300 rounded basis-1/2"></div>
                                        </div>
                                        <div class="h-2 bg-gray-300 rounded"></div>
                                        <div class="h-2 bg-gray-300 rounded"></div>
                                        <div class="h-2 bg-gray-300 rounded"></div>
                                        <div class="h-2 bg-gray-300 rounded"></div>
                                        <div class="h-2 bg-gray-300 rounded"></div>
                                        <div class="h-2 bg-gray-300 rounded"></div>
                                        <div class="h-2 bg-gray-300 rounded"></div>
                                        <div class="h-2 bg-gray-300 rounded"></div>
                                        {/* <div class="h-2 bg-gray-300 rounded"></div> */}
                                        {/* <div class="space-y-3">
                                                    <div class="grid grid-cols-3 gap-4">
                                                        <div class="h-2 bg-gray-300 rounded col-span-2"></div>
                                                        <div class="h-2 bg-gray-300 rounded col-span-1"></div>
                                                    </div>
                                                    <div class="h-2 bg-gray-300 rounded"></div>
                                                </div> */}
                                    </div>
                                </div>
                            ) : (
                                <CardContent style={{ flexGrow: 1 }}>
                                    <Stack sx={{ marginTop: 0 }} spacing={2}>
                                        <Typography variant='subtitle2' color="#A4A4A4" fontWeight='bold'>Food Order Details</Typography>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <ShoppingBag htmlColor="#A4A4A4" />
                                            <Typography variant='subtitle2' color="text.secondary" >{foodOrder?.cart?.map(item => item.foodItem.itemName).join(', ')}</Typography>
                                        </Stack>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <AttachMoney htmlColor="#A4A4A4" />
                                            <Typography variant='subtitle2' color="text.secondary" >RM{Number(foodOrder?.totalPayment).toFixed(2)}</Typography>
                                        </Stack>
                                        {/* {
                                            foodOrder?.counterOffer && (
                                                <Stack direction='row' alignItems='center' spacing={1}>
                                                    <CurrencyExchange htmlColor="#A4A4A4" />
                                                    <Typography variant='subtitle2' color="text.secondary" >RM{Number(foodOrder?.counterOffer).toFixed(2)}</Typography>
                                                </Stack>
                                            )
                                        } */}

                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <Route htmlColor="#A4A4A4" />
                                            <Typography variant='subtitle2' color="text.secondary" >{foodOrder?.status}</Typography>
                                        </Stack>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <Numbers htmlColor="#A4A4A4" />
                                            <Typography variant='subtitle2' color="text.secondary" >{foodOrder?.orderNo}</Typography>
                                        </Stack>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <PointOfSale htmlColor="#A4A4A4" />
                                            <Typography variant='subtitle2' color="text.secondary" >{foodOrder?.paymentMethod}</Typography>
                                        </Stack>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <Person htmlColor="#A4A4A4" />
                                            <Typography variant='subtitle2' color="text.secondary" >{foodOrder?.user?.firstName} {foodOrder?.user?.lastName}</Typography>
                                        </Stack>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <Restaurant htmlColor="#A4A4A4" />
                                            <Typography variant='subtitle2' color="text.secondary" >{foodOrder?.merchant?.merchantName}</Typography>
                                        </Stack>
                                        <Stack direction='row' alignItems='center' spacing={1}>
                                            <CalendarToday htmlColor="#A4A4A4" />
                                            <Typography variant='subtitle2' color="text.secondary" >{foodOrder?.createdAt && format(new Date(foodOrder?.createdAt), 'dd MMMM yyy, p')}</Typography>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            )
                        }

                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card sx={{ padding: 2 }}>
                        {
                            loading === HTTP_STATUS.PENDING ? (
                                // true ? (
                                <div class="animate-pulse flex space-x-4 py-7">

                                    <div class="flex-1 space-y-10">
                                        <div className='flex flex-row items-center space-x-3'>
                                            {/* <div class="rounded-full bg-gray-300 h-10 w-10"></div> */}
                                            <div class="h-5 bg-gray-300 rounded basis-1/2"></div>
                                        </div>
                                        <div class="h-5 bg-gray-300 rounded"></div>
                                        <div class="h-5 bg-gray-300 rounded"></div>
                                        <div class="h-5 bg-gray-300 rounded"></div>
                                        <div class="h-5 bg-gray-300 rounded"></div>
                                        <div class="h-5 bg-gray-300 rounded"></div>
                                        <div class="h-5 bg-gray-300 rounded"></div>
                                        {/* <div class="h-2 bg-gray-300 rounded"></div> */}
                                        {/* <div class="space-y-3">
                                                    <div class="grid grid-cols-3 gap-4">
                                                        <div class="h-2 bg-gray-300 rounded col-span-2"></div>
                                                        <div class="h-2 bg-gray-300 rounded col-span-1"></div>
                                                    </div>
                                                    <div class="h-2 bg-gray-300 rounded"></div>
                                                </div> */}
                                    </div>
                                </div>
                            ) : (
                                <CardContent style={{ flexGrow: 1 }}>
                                    <Typography variant='h6' fontWeight='bold'>Edit</Typography>
                                    <Box component='form' onSubmit={handleSubmit}>
                                        <Stack direction='row' justifyContent='space-between'>

                                            <Stack marginY={1} flex={1} spacing={2}>

                                                {/* <TextField id="request" sx={{ '& > :not(style)': { width: '40ch' }, }} name='request' defaultValue={foodOrder?.request} key={foodOrder.request} margin='normal' label="Request" variant="standard" /> */}

                                                {/* <TextField id="offer" sx={{ '& > :not(style)': { width: '40ch' }, }} name='offer' defaultValue={foodOrder?.offer} key={foodOrder.offer} margin='normal' label="Offer" variant="standard" /> */}



                                                {/* <TextField disabled id="orderNo" sx={{ '& > :not(style)': { width: '40ch' }, }} name='orderNo' defaultValue={buyOrder?.orderNo} key={buyOrder.orderNo} margin='normal' label="Order No." variant="standard" /> */}

                                                {/* <TextField disabled id="paymentMethod" sx={{ '& > :not(style)': { width: '40ch' }, }} name='paymentMethod' defaultValue={buyOrder?.paymentMethod} key={buyOrder.paymentMethod} margin='normal' label="Payment Method" variant="standard" /> */}
                                                {/* <Autocomplete
                                                    disablePortal
                                                    id="paymentMethod"
                                                    options={orderPaymentMethods}
                                                    sx={{ width: 300 }}
                                                    defaultValue={buyOrder?.paymentMethod}
                                                    key={buyOrder?.paymentMethod}
                                                    renderInput={(params) => <TextField {...params} label="Payment Method" name='paymentMethod' key={buyOrder.paymentMethod} required margin='normal' />}
                                                // onChange={(event, value) => setPayment(value.label)}
                                                /> */}

                                                <Autocomplete
                                                    disablePortal
                                                    id="status"
                                                    options={orderStatus}
                                                    sx={{ width: 300 }}
                                                    defaultValue={foodOrder?.status}
                                                    key={foodOrder?.status}
                                                    renderInput={(params) => <TextField {...params} label="Order Status" name='status' key={foodOrder.status} required margin='normal' />}
                                                // onChange={(event, value) => setPayment(value.label)}
                                                />

                                                <Typography variant='h6' fontWeight='bold'>Order Details</Typography>

                                                {
                                                    foodOrder?.cart?.map(item => (
                                                        <div key={item._id} className='flex flex-row space-x-3 items-center py-2'>
                                                            <img alt='order' src={item?.foodItem?.imageUrl} width="100" height="50" />
                                                            <div className='flex flex-col h-full justify-between'>
                                                                <Typography>{item?.foodItem?.itemName}</Typography>
                                                                <Typography>Price: RM {item?.foodItem?.price?.toFixed(2)}</Typography>
                                                                <Typography>Quantity: {item?.quantity}</Typography>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                                <Typography>Discount: RM {foodOrder?.discount?.toFixed(2)}</Typography>
                                                <Typography>Total Payment: RM {foodOrder?.totalPayment?.toFixed(2)}</Typography>

                                                <Typography variant='h6' fontWeight='bold'>User Location</Typography>

                                                <Stack>
                                                    <Typography><span style={{ fontWeight: 'bold' }}>Address: </span>{foodOrder?.userAddress?.addressName}, {foodOrder?.userAddress?.address}</Typography>
                                                    <Typography><span style={{ fontWeight: 'bold' }}>Unit details: </span>{foodOrder?.userAddress?.unitDetails}</Typography>
                                                    <Typography><span style={{ fontWeight: 'bold' }}>Name: </span>{foodOrder?.userAddress?.fullName}</Typography>
                                                    <Typography><span style={{ fontWeight: 'bold' }}>Mobile Number:</span>{foodOrder?.userAddress?.mobileNumber}</Typography>
                                                </Stack>

                                                {
                                                    isLoaded && (
                                                        <GoogleMap
                                                            mapContainerStyle={containerStyle}
                                                            center={center}
                                                            zoom={16}
                                                            onLoad={onLoad}
                                                            onUnmount={onUnmount}
                                                        >
                                                            {
                                                                map && (
                                                                    <Marker
                                                                        // icon={{
                                                                        //     path: google.maps.SymbolPath.CIRCLE,
                                                                        //     scale: 7,
                                                                        // }}
                                                                        // icon={'/assets/sv_logo.jpg'}
                                                                        position={position}
                                                                        title='User Location'
                                                                    />
                                                                )
                                                            }

                                                            { /* Child components, such as markers, info windows, etc. */}
                                                        </GoogleMap>
                                                    )
                                                }

                                                {/* {
                                                    foodOrder.foodOrder.map(item => (
                                                        <Typography></Typography>
                                                    ))
                                                } */}

                                                <Button sx={{ mt: 3 }} type='submit' variant='contained'>Update</Button>
                                            </Stack>

                                            <Stack justifyContent='flex-end'>

                                            </Stack>

                                        </Stack>
                                    </Box>
                                </CardContent>
                            )
                        }

                    </Card>
                </Grid>
            </Grid>
        </Stack>
    );
}
