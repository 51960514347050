import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { PURGE } from "redux-persist";
import axios from 'axios'
import { HTTP_STATUS } from '../utils/constants'
const { REACT_APP_API_URL } = process.env

export const sendMessage = createAsyncThunk(
    'message/sendMessage',
    async (data, thunkAPI) => {
        // const {email, password} = userData
        return await axios.post(`${REACT_APP_API_URL}message`, data)
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    // setLoginError(true)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
            })
            .catch(err => console.log(err))
    }
)

export const getUserFeedbacks = createAsyncThunk(
    'message/getUserFeedbacks',
    async (thunkAPI) => {
        // const {email, password} = userData
        return await axios.get(`${REACT_APP_API_URL}user/contact`)
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    // setLoginError(true)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
            })
            .catch(err => console.log(err))
    }
)

export const getAgentFeedbacks = createAsyncThunk(
    'message/getAgentFeedbacks',
    async (thunkAPI) => {
        // const {email, password} = userData
        return await axios.get(`${REACT_APP_API_URL}agent/contact`)
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    // setLoginError(true)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
            })
            .catch(err => console.log(err))
    }
)

export const getSupportCases = createAsyncThunk(
    'message/getSupportCases',
    async (thunkAPI) => {
        // const {email, password} = userData
        return await axios.get(`${REACT_APP_API_URL}supportCases`)
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    // setLoginError(true)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
            })
            .catch(err => console.log(err))
    }
)

export const getSupportMessages = createAsyncThunk(
    'message/getSupportMessages',
    async (id, thunkAPI) => {
        console.log('hello')
        // const {email, password} = userData
        return await axios.get(`${REACT_APP_API_URL}supportMessages`, {
            params: {
                id
            }
        })
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    // setLoginError(true)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
            })
            .catch(err => console.log(err))
    }
)

export const updateSupportCaseAsResolved = createAsyncThunk(
    'message/updateSupportCaseAsResolved',
    async ({id, successMessage}, thunkAPI) => {
        console.log('hello')
        // const {email, password} = userData
        return await axios.put(`${REACT_APP_API_URL}supportMessageResolved`, {id})
            .then(result => {
                // console.log(result)
                if (result.data.success) {
                    successMessage()
                    // setLoginError(true)
                    // thunkAPI.dispatch(result.data.profile)
                    return result.data
                } else {
                    return thunkAPI.rejectWithValue(result.data.message)
                    // setLoginError(false)
                }
            })
            .catch(err => console.log(err))
    }
)


const initialState = {
    status: '',
    loading: null,
    message: '',
    userFeedbacks: [],
    agentFeedbacks: [],
    newSupportCases: [],
    resSupportCases: [],
    supportMessages: [],
    // newSupportCasesFiltered: [],
}

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        setSupportMessages: (state, action) => {
            state.supportMessages = [...state.supportMessages, action.payload]
        },
        resetSupportMessages: (state, action) => {
            state.supportMessages = []
        },
        setNewSupportCases: (state, action) => {
            state.newSupportCases = action.payload
        },
        // purgeData: (builder) => {
        //     builder.addCase(PURGE, (state) => {
        //         builder.removeAll(state);
        //     });
        // }
    },
    extraReducers: {
        [sendMessage.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
            // state.status = 'loading'
        },
        [sendMessage.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            // state.status = 'success';
            // state.adminProfile = action.payload.profile;
        },
        [sendMessage.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
            // state.status = 'failed';
            // state.message = action.payload;
        },
        [getUserFeedbacks.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
        },
        [getUserFeedbacks.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.userFeedbacks = action.payload.userContacts
        },
        [getUserFeedbacks.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
        },
        [getAgentFeedbacks.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
        },
        [getAgentFeedbacks.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.agentFeedbacks = action.payload.agentContacts
        },
        [getAgentFeedbacks.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
        },
        // Get Support Cases
        [getSupportCases.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
        },
        [getSupportCases.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.newSupportCases = action.payload.newSupportCases
            // state.resSupportCases = action.payload.resSupportCases
        },
        [getSupportCases.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
        },
        // Get Support Messages
        [getSupportMessages.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
        },
        [getSupportMessages.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.supportMessages = action.payload.supportMessages
        },
        [getSupportMessages.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
        },
        // Get Support Messages
        [updateSupportCaseAsResolved.pending]: (state, action) => {
            state.loading = HTTP_STATUS.PENDING
        },
        [updateSupportCaseAsResolved.fulfilled]: (state, action) => {
            state.loading = HTTP_STATUS.FULFILLED
            state.newSupportCases = action.payload.newSupportCases
            state.supportMessages = []
        },
        [updateSupportCaseAsResolved.rejected]: (state, action) => {
            state.loading = HTTP_STATUS.REJECTED
        },
    },
})

// Action creators are generated for each case reducer function
export const { setSupportMessages, resetSupportMessages, setNewSupportCases } = messageSlice.actions
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default messageSlice.reducer