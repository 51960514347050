import React, { useState, useEffect } from 'react';
import { images } from '../../assets'
import { DataGrid } from '@mui/x-data-grid';
import { Stack, Avatar, IconButton, Typography, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { HTTP_STATUS, PAGE_SIZE } from '../../utils/constants'
import { getFoodOrders, setPostBuyOrderStatus, setUpdateFoodOrderStatus, setSelectedFoodOrderId, deleteFoodOrder } from '../../redux/foodOrder.js'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FoodOrderList() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { foodOrders, foodOrdersCount, deleteFoodOrderStatus, loading } = useSelector(state => state.foodOrder)

  // PAGINATION
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const [foodOrderId, setFoodOrderId] = useState('');

  // Dialog Section
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect Section
  useEffect(() => {
    // dispatch(setPostBuyOrderStatus())
    // dispatch(setUpdateFoodOrderStatus())
    console.log(foodOrders[foodOrders.length - 1]?.origId)
    const data = {
      page,
      pageSize,
    }
    dispatch(getFoodOrders(data))
  }, [page])

  useEffect(() => {
    console.log(deleteFoodOrderStatus)
    if (deleteFoodOrderStatus === 'success') {
      console.log('success')
      const data = {
        page,
        pageSize,
      }
      dispatch(getFoodOrders(data))
    }
  }, [deleteFoodOrderStatus])

  // const [data, setData] = useState(buyOrders)

  // console.log('data', data)

  // const [data, setData] = useState([
  //   { id: 1, user: 'Jon Snow', date: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 2, user: 'Jon Snow', date: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 3, user: 'Jon Snow', date: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 4, user: 'Jon Snow', date: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 5, user: 'Jon Snow', date: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 6, user: 'Jon Snow', date: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 7, user: 'Jon Snow', date: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 8, user: 'Jon Snow', date: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 9, user: 'Jon Snow', date: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 10, user: 'Jon Snow', date: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  //   { id: 11, user: 'Jon Snow', date: 'musta@gmail.com', mobile: '+60141355667', transactions: 'RM244' },
  // ])



  const handleDialog = id => {
    console.log(id)
    setFoodOrderId(id)
    handleClickOpen()
  }

  const handleDelete = () => {

    // const formData = new FormData()
    // formData.append('id', buyOrderId)

    dispatch(deleteFoodOrder(foodOrderId))
    handleClose()
    // setData(users.filter(item => item.origId !== origId))
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
      field: 'merchant', headerName: 'Merchant', width: 150, renderCell: (params) => (
        <Stack direction='row' alignItems='center' spacing={1}>
          <Avatar alt="user" src={params.row.merchantProfilePhoto} />
          <span>
            {params.row.merchant}
          </span>
        </Stack>
      )
    },
    {
      field: 'user', headerName: 'User', width: 150, renderCell: (params) => (
        <Stack direction='row' alignItems='center' spacing={1}>
          <Avatar alt="user" src={params.row.userProfilePhoto} />
          <span>
            {params.row.user}
          </span>
        </Stack>
      )
    },
    // { field: 'lastName', headerName: 'Last name', width: 100 },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 140,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
    {
      field: 'cart',
      headerName: 'Order',
      // type: 'number',
      width: 150,
    },
    // {
    //   field: 'placeName',
    //   headerName: 'Place Name',
    //   // type: 'number',
    //   width: 150,
    // },
    // {
    //   field: 'placeAddress',
    //   headerName: 'Place Address',
    //   // type: 'number',
    //   width: 200,
    // },
    // {
    //   field: 'userAddress',
    //   headerName: 'User Address',
    //   // type: 'number',
    //   width: 200,
    // },
    {
      field: 'totalPayment',
      headerName: 'Total',
      // type: 'number',
      width: 80,
    },
    {
      field: 'paymentMethod',
      headerName: 'Method',
      // type: 'number',
      width: 120,
    },
    // {
    //   field: 'paymentMethod',
    //   headerName: 'Payment Method',
    //   // type: 'number',
    //   width: 150,
    // },
    {
      field: 'orderNo',
      headerName: 'Order No.',
      // type: 'number',
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      // type: 'number',
      width: 120,
      renderCell: params => (
        <Chip label={params.row.status} color={params.row.status === 'Completed' ? 'success' : params.row.status === 'Pending Payment' || params.row.status === 'Payed' || params.row.status === 'On the Way' || params.row.status === 'Delivered' ? 'warning' : params.row.status === 'Searching' ? 'info' : 'error'} />
      )
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      // type: 'number',
      width: 150,
    },
    {
      field: 'action', headerName: 'Action', width: 100, renderCell: params => (
        <Stack direction="row" spacing="2" alignItems='center'>
          <Link to={"/food-order/" + params.row.id}>
            <IconButton onClick={() => dispatch(setSelectedFoodOrderId(params.row.origId))} color="success" aria-label="edit buy order" component="span">
              <Edit />
            </IconButton>
          </Link>
          <IconButton onClick={() => handleDialog(params.row.origId)} color="error" aria-label="delete buy order" component="span">
            <Delete />
          </IconButton>
        </Stack>

      )
    },
  ];

  return (
    <Stack className='basis-full p-4'>
      <Stack direction='row' alignItems='center' justifyContent='space-between' paddingY={2}>
        <Typography variant='h5' fontWeight='bold'>Food Order List</Typography>
        {/* <Button variant='contained' onClick={() => navigate('/new-food-order')}>
          Create
        </Button> */}
        <Button variant='contained' onClick={() => console.log(foodOrders)}>
          Test
        </Button>
      </Stack>
      {
        loading === HTTP_STATUS.PENDING ? (
          // true ? (
          <div className="animate-pulse flex p-7">

            <div className="flex-1 space-y-10">
              <div className="h-5 bg-gray-300 rounded w-1/2"></div>
              <div className="h-5 bg-gray-300 rounded"></div>
              <div className="h-5 bg-gray-300 rounded"></div>
              <div className="h-5 bg-gray-300 rounded"></div>
              <div className="h-5 bg-gray-300 rounded"></div>
              {/* <div class="h-2 bg-gray-300 rounded"></div> */}
              {/* <div class="space-y-3">
                                  <div class="grid grid-cols-3 gap-4">
                                      <div class="h-2 bg-gray-300 rounded col-span-2"></div>
                                      <div class="h-2 bg-gray-300 rounded col-span-1"></div>
                                  </div>
                                  <div class="h-2 bg-gray-300 rounded"></div>
                              </div> */}
            </div>
          </div>
        ) : (
          // <DataGrid
          //   rows={foodOrders}
          //   columns={columns}
          //   pageSize={8}
          //   rowsPerPageOptions={[5]}
          //   checkboxSelection
          //   disableSelectionOnClick
          //   autoPageSize
          //   autoHeight={true}
          // />
          <DataGrid
            rows={foodOrders}
            rowCount={foodOrdersCount}
            checkboxSelection
            disableSelectionOnClick
            autoHeight={true}
            // autoPageSize
            // loading={loading === HTTP_STATUS.PENDING ? true : false}
            rowsPerPageOptions={[8]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={columns}
            // initialState={initialState}
          />
        )
      }


      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirm delete buy order?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this buy order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleDelete}>Yes</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
